import { subWeeks } from "date-fns";
import { findIndex, now, sortBy } from "lodash";
import { useMemo } from "react";

import { ID, Sprint } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useMarkAsSeen,
  useUpdateEntity,
} from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";

import { cx } from "@utils/class-names";
import { formatDay } from "@utils/date";
import { fromCalDate, useCalDate, useISODate } from "@utils/date-fp";
import { when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Centered, Container } from "@ui/container";
import { DateRangePicker } from "@ui/date-picker";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { SprintPeopleCards } from "@ui/engine/sprint";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { SlidePaneManager } from "@ui/entity-pane-manager";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowLeft, ArrowRight } from "@ui/icon";
import { PackageTag } from "@ui/package-label";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { PropertyValueButton } from "@ui/property-value-button";
import {
  LabelledPropertyValueList,
  PropertyValueTile,
} from "@ui/property-value-tile";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SubRouter, SubRoutesMenu } from "@ui/sub-router";
import { TemplateBanner } from "@ui/template-banner";
import { Text } from "@ui/text";
import { ViewSubRoute } from "@ui/view-pane";
import { ViewRelationsSection } from "@ui/view-results-section";
import { WorkflowActions } from "@ui/workflow-action-button";

import AppPage from "./app-page";

import styles from "./sprint-page.module.css";

interface Props {
  sprintId: string;
}

const SprintPage = ({ sprintId }: Props) => {
  const pageId = usePageId();
  const sprint = useLazyEntity<"sprint">(sprintId);

  const [page] = useRegisterPage(sprintId, sprint);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(sprintId, sprint?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(sprintId, pageId);

  if (!sprint) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!sprint} title={sprint?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          {!!sprint.template && <TemplateBanner />}

          <PageLayout>
            <Header entity={sprint} />

            <SubRouter
              id={sprintId}
              router={(r) =>
                r.pathname === "/" ? (
                  <OverviewPage id={sprintId} />
                ) : r.id ? (
                  <Main className={styles.main}>
                    <ViewSubRoute id={sprintId} viewId={r.id} />
                  </Main>
                ) : (
                  <></>
                )
              }
            />
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

export default SprintPage;

const OverviewPage = ({ id }: { id: ID }) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const sprint = useLazyEntity<"sprint">(id);
  const mutate = useUpdateEntity(id, pageId);

  const installed = useHasPackages(id, [
    SystemPackages.Outcomes,
    SystemPackages.Meetings,
    SystemPackages.Processes,
    SystemPackages.Workflows,
  ]);

  if (!sprint) {
    return <></>;
  }

  return (
    <>
      <Main className={styles.main}>
        <EntityHeaderBar entity={sprint} padding="both" showTag={false} />

        <Centered stack="vertical" gap={60}>
          <SpaceBetween gap={20}>
            <VStack gap={20}>
              <Container
                padding="none"
                stack="horizontal"
                inset="bottom"
                gap={16}
              >
                <PropertyValueTile
                  entity={sprint}
                  field={"status"}
                  onChange={mutate}
                />

                <PropertyValueTile
                  entity={sprint}
                  field={"start"}
                  onChange={mutate}
                />
                <PropertyValueTile
                  entity={sprint}
                  field={"duration"}
                  onChange={mutate}
                />
                <PropertyValueTile
                  entity={sprint}
                  field={"end"}
                  onChange={mutate}
                />
              </Container>

              <HStack fit="container" gap={4}>
                {!sprint.template && <WorkflowActions entity={sprint} />}
              </HStack>
            </VStack>

            <DateRangePicker
              className={styles.calendar}
              dates={{
                from: fromCalDate(sprint.start),
                to: fromCalDate(sprint.end),
              }}
              onChanged={() => {}}
            />
          </SpaceBetween>

          <SprintPeopleCards sprintId={id} />

          {installed[SystemPackages.Workflows] && (
            <ViewRelationsSection
              parentId={sprint.id}
              childType="workflow"
              layout="card"
              onOpen={pushTo}
            />
          )}

          {installed[SystemPackages.Outcomes] && (
            <ViewRelationsSection
              parentId={sprint.id}
              childType="outcome"
              onOpen={pushTo}
            />
          )}
          <ViewRelationsSection
            parentId={sprint.id}
            childType="task"
            onOpen={pushTo}
          />
        </Centered>
      </Main>
      <SlidePaneManager id={id} entity={sprint} />
    </>
  );
};

interface HeaderProps {
  entity: Sprint;
  className?: string;
}

export const Header = ({ entity, className }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(entity.id, pageId);

  if (!entity) {
    return <h1>Not found.</h1>;
  }

  return (
    <SideNav className={cx(styles.nav, className)}>
      <SpaceBetween direction="vertical">
        <VStack gap={30} fit="container">
          <PackageTag type={entity.source.type} scope={entity.source.scope} />

          <VStack gap={12} fit="container">
            <HStack gap={0}>
              <MagicEmojiSelect
                key={`${entity.id}-emoji`}
                entity={entity}
                className={styles.icon}
                size="large"
                emoji={entity.icon || "🏃‍♂️"}
                onChange={(icon) =>
                  when(icon, (i) =>
                    mutate(asMutation({ field: "icon", type: "text" }, i))
                  )
                }
              />

              <Container padding="none" inset="both">
                <PropertyValueButton
                  entity={entity}
                  field="status"
                  type="status"
                />
              </Container>
            </HStack>

            <VStack gap={8}>
              <EditableHeading
                key={`${entity.id}-heading`}
                text={entity.name || ""}
                size="h2"
                className={styles.heading}
                onChange={(text) => {
                  when(text, (i) => {
                    mutate(asMutation({ field: "name", type: "text" }, i));
                  });
                }}
              />

              <HStack>
                {entity.start && (
                  <Text subtle>{useISODate(entity.start, formatDay)}</Text>
                )}
                <Text subtle> - </Text>
                {entity.end && (
                  <Text subtle>{useISODate(entity.end, formatDay)}</Text>
                )}
              </HStack>
            </VStack>

            <Divider margin="on" />

            <NextPrevSprints sprint={entity} />

            <Divider margin="on" />

            <SubRoutesMenu entity={entity} />

            <Divider margin="on" />

            <LabelledPropertyValueList
              stack="vertical"
              gap={10}
              entity={entity}
              onChange={mutate}
            />
          </VStack>
        </VStack>
      </SpaceBetween>
    </SideNav>
  );
};

const NextPrevSprints = ({ sprint }: { sprint: Sprint }) => {
  const goTo = useGoTo();
  const related = useLazyQuery(
    "sprint",
    useMemo(
      () => ({
        field: "start",
        type: "date",
        op: "after",
        value: {
          date: useCalDate(sprint?.start, (s) => subWeeks(s || now(), 6)),
        },
      }),
      [sprint?.start]
    )
  );
  const sorted = useMemo(() => sortBy(related, (r) => r.start), [related]);
  const current = useMemo(
    () => findIndex(sorted, (r) => r.id === sprint.id),
    [sorted]
  );
  const next = useMemo(() => sorted[current + 1], [sorted, current]);
  const prev = useMemo(() => sorted[current - 1], [sorted, current]);

  return (
    <SpaceBetween gap={10}>
      <Button
        fit="container"
        disabled={!prev}
        icon={ArrowLeft}
        onClick={() => goTo(prev)}
      >
        {prev?.name}
      </Button>
      <Button
        fit="container"
        disabled={!next}
        iconRight={ArrowRight}
        onClick={() => next && goTo(next)}
        className={styles.rightAlign}
      >
        {next?.name || " "}
      </Button>
    </SpaceBetween>
  );
};
