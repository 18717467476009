import { useCallback, useMemo } from "react";

import { Entity, ID, isTeam, Team } from "@api";

import { useMe } from "@state/persons";
import { toLabel as toTeamLabel, useLazyAllTeams } from "@state/teams";

import {
  FlowDefinition,
  toFlowDefinition,
  treeLayout,
  useFlowData,
} from "@utils/chart";
import { cx } from "@utils/class-names";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toPropertyValueRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";

import { Ellipsis } from "@ui/ellipsis";
import {
  BaseEdge,
  EdgeProps,
  getSmoothStepPath,
  Node,
  NodeProps,
  Position,
} from "@ui/flow";
import { Flow, Handle, withFlowProvider } from "@ui/flow";
import { TeamIcon } from "@ui/icon";
import { TextMedium } from "@ui/text";

import styles from "./team-structure-flow.module.css";

type NodeData = {
  label: string;
  entity: Entity;
  parentId?: ID;
  editable?: boolean;
  selected?: boolean;
  faint?: boolean;
};

type EdgeData = {
  label: Maybe<string>;
  edge?: boolean;
};

interface Props {
  whitelist?: string[];
  className?: string;
  inverseColors?: boolean;
  mode?: "all" | "me";
}

export const TeamStructureFlow = withFlowProvider(
  ({ className, mode = "all", inverseColors, whitelist }: Props) => {
    const me = useMe();
    const teams = useLazyAllTeams();
    const goTo = useGoTo();

    const strat = useMemo(
      () =>
        treeLayout(
          { width: 120, height: 90, vSpace: 8 },
          { orientation: "vertical" }
        ),
      []
    );
    const nodeTypes = useMemo(
      () => ({ team: TeamNode(false, inverseColors || false) }),
      []
    );
    const edgeTypes = useMemo(() => ({ team: TeamEdge }), []);

    const flowDef = useMemo(
      (): FlowDefinition<Team, NodeData, EdgeData> =>
        toFlowDefinition<Team, NodeData, EdgeData>({
          layout: strat,
          nodeTypes,
          edgeTypes,
          fitView: { maxZoom: 1.5, duration: 200, minZoom: 0.5, padding: 0.05 },
          toNode: (team) => ({
            id: team.id,
            draggable: false,
            data: {
              label: toTeamLabel(team),
              entity: team,
              faint: mode === "me" && !containsRef(team?.people, me),
            },
            type: "team",
          }),
          toEdges: (team) => {
            const parentValueRef = toPropertyValueRef(team, {
              field: "parent",
              type: "relation",
            });
            const parent = parentValueRef?.value.relation;

            if (!parent || !parent.id) {
              return [];
            }

            return [
              {
                id: `${team.id}-${parent.id}`,
                source: parent.id,
                target: team.id,
                type: "team",
                data: {
                  label: "",
                  inverse: inverseColors,
                  color: "blue_5",
                },
                markerEnd: undefined,
              },
            ];
          },
        }),
      [teams, whitelist, mode]
    );

    const { nodes, edges, toPosition, ...flowProps } = useFlowData(
      teams,
      flowDef
    );

    const onNodeClicked = useCallback(
      (_event: React.MouseEvent, node: Node) => when(node.data.entity, goTo),
      []
    );

    return (
      <Flow
        className={cx(className, styles.flow)}
        nodes={nodes}
        edges={edges}
        {...flowProps}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        draggable={false}
        panOnDrag={false}
        onNodeClick={onNodeClicked}
      >
        <></>
      </Flow>
    );
  }
);

export const TeamNode =
  (editable: boolean, inverseColors: boolean) =>
  ({ data: { entity, faint } }: NodeProps<NodeData>) => {
    const pushTo = useGoTo();
    const team = isTeam(entity) ? entity : undefined;

    if (!team) {
      return <></>;
    }

    return (
      <div
        className={cx(styles.team, faint && styles.faint)}
        onDoubleClick={() => pushTo(entity)}
      >
        <Handle
          className={cx(styles.handle, !editable && styles.hidden)}
          type="target"
          position={Position.Top}
        />

        <TeamIcon
          team={team}
          className={styles.largeIcon}
          color={faint ? "gray_5" : undefined}
        />

        <TextMedium
          bold
          className={cx(styles.label, inverseColors && styles.white)}
        >
          <Ellipsis>{team.name}</Ellipsis>
        </TextMedium>

        <Handle
          className={cx(styles.handle, !editable && styles.hidden)}
          type="source"
          position={Position.Bottom}
        />
      </div>
    );
  };

export function TeamEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX: sourceX,
    sourceY: sourceY,
    targetX: targetX,
    targetY: targetY,
    borderRadius: 40,
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
  });

  const color = data.inverse
    ? "color-mix(in srgb, var(--color-border) 40%, black)"
    : "var(--color-border)";

  return (
    <>
      <defs>
        <marker
          id={`${id}-edge`}
          viewBox="0 0 14 8"
          refX="7"
          refY="5"
          markerWidth="7"
          markerHeight="4"
          fill="none"
        >
          <path
            d="M1.27034 1.27042L6.99992 7L12.7295 1.27042"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </marker>
      </defs>

      <BaseEdge
        id={id}
        path={edgePath}
        style={{ strokeWidth: "1px", stroke: color }}
      />
    </>
  );
}
