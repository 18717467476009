import { find, findLast, last, replace } from "lodash";
import { useMemo } from "react";

import { EntityType, isBaseScopeEntity, isSubRouter } from "@api";

import { isFormId } from "@state/form";
import { useLazyEntity } from "@state/generic";

import { composel } from "@utils/fn";
import { maybeTypeFromId, typeFromId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { required, safeAs, when } from "@utils/maybe";
import { usePathName } from "@utils/navigation";
import { fromScope } from "@utils/scope";

import { getEngine, render } from "@ui/engine";
import { FormBuilderPage } from "@ui/engine/form";
import FormPage from "@ui/engine/form/page";
import DocumentPage from "@ui/engine/page/page";
import { PipelinePage } from "@ui/engine/pipeline";
import { WorkflowEditorPage } from "@ui/engine/workflow/editor";
import BacklogPage from "@ui/page/backlog-page";
import CalendarPage from "@ui/page/calendar-page";
import { EntityOnlyPage, EntityPage } from "@ui/page/entity-page";
import HomePage from "@ui/page/home-page";
import { ImportWorkPage } from "@ui/page/import-work-page";
import InboxPage from "@ui/page/inbox-page";
import MeetingPage from "@ui/page/meeting-page";
import { MyMeetingsPage } from "@ui/page/my-meetings";
import OutboxPage from "@ui/page/outbox-page";
import PersonPage from "@ui/page/person-page";
import { ProjectPage } from "@ui/page/project-page";
import ResourcePage from "@ui/page/resource-page";
import RoadmapPage from "@ui/page/roadmap-page";
import { SettingsPage } from "@ui/page/settings-page";
import SprintPage from "@ui/page/sprint-page";
import TeamPage from "@ui/page/team-page";
import TeamSettingsPage from "@ui/page/team-settings-page";
import { ViewPage } from "@ui/page/view-page";
import { Redirect } from "@ui/redirect";

import PrivateSettingsPage from "./private-settings-page";

interface Props {
  part: string;
  parents?: string[];
}

export function AppRoute({ part: _part, parents }: Props) {
  return useMemo(() => {
    const baseId = parents?.[0];
    const baseType = maybeTypeFromId<EntityType>(baseId);
    const isNesting = !!when(baseType, isBaseScopeEntity);
    const isTeamNesting = baseId && isNesting && baseType === "team";

    let part = _part;
    let matchingOn = maybeTypeFromId(part) || part;

    // If the last is a view and there is a nestable parent, use that instead and it will
    // handle the inner routing
    if (
      matchingOn === "view" &&
      when(
        safeAs<EntityType>(last(parents)),
        composel(maybeTypeFromId, isSubRouter)
      )
    ) {
      part = required(last(parents), () => "Expected parent for view.");
      matchingOn = when(part, maybeTypeFromId) || part;
    }

    // Update on new entity
    // Otherwise route base on last item
    return switchEnum(matchingOn, {
      action: () => <ParentRedirect entityId={part} type="meeting" />,
      agenda: () => <ParentRedirect entityId={part} type="meeting" />,
      workflow_step: () => <ParentRedirect entityId={part} type="workflow" />,

      // Custom Entity Pages
      pipeline: () => <PipelinePage id={part} />,
      form: () => <FormPage id={part} />,
      project: () => <ProjectPage projectId={part} />,
      campaign: () => <ProjectPage projectId={part} />,
      backlog: () => <BacklogPage id={part} />,
      sprint: () => <SprintPage sprintId={part} />,
      meeting: () => <MeetingPage meetingId={part} />,
      team: () => <TeamPage teamId={part} />,
      note: () => <EntityOnlyPage id={part} />,
      calendar: () => <CalendarPage id={part} />,
      roadmap: () => <RoadmapPage id={part} />,
      resource: () => <ResourcePage resourceId={part} />,

      // Custom non-id routes
      builder: () => {
        const parentId = last(parents);
        return switchEnum(typeFromId<EntityType>(parentId || ""), {
          form: () => (
            <FormBuilderPage
              id={required(
                find(parents, isFormId),
                () => "Not a form builder route."
              )}
            />
          ),

          workflow: () => (
            <WorkflowEditorPage
              id={required(
                last(parents),
                () => "Not a workflow builder route."
              )}
            />
          ),
          else: () => {
            throw new Error("Not a valid builder route.");
          },
        });
      },

      // Generic Use EntityPage
      task: () => <EntityOnlyPage id={part} />,
      outcome: () => <EntityOnlyPage id={part} />,
      workflow: () => <EntityOnlyPage id={part} />,
      content: () => <EntityOnlyPage id={part} />,
      page: () => <DocumentPage id={part} />,
      process: () => <EntityOnlyPage id={part} />,
      event: () => <EntityOnlyPage id={part} />,
      deal: () => <EntityOnlyPage id={part} />,
      company: () => <EntityOnlyPage id={part} />,
      contact: () => <EntityOnlyPage id={part} />,

      view: () => <ViewPage viewId={part} />,

      meetings: () =>
        isTeamNesting ? <TeamPage teamId={baseId} /> : <MyMeetingsPage />,
      settings: () =>
        !!baseId && baseType === "team" ? (
          <TeamSettingsPage teamId={baseId} />
        ) : baseType === "person" ? (
          <PrivateSettingsPage />
        ) : (
          <SettingsPage />
        ),
      home: () => <HomePage />,
      person: () => <PersonPage personId={part} />,
      inbox: () => <InboxPage />,
      outbox: () => <OutboxPage />,
      import: () => <ImportWorkPage />,

      else: () => {
        const engine = when(maybeTypeFromId<EntityType>(part), getEngine);

        if (engine && engine.asPage) {
          return render(engine.asPage, { id: part });
        }

        if (
          isNesting &&
          !!baseId &&
          equalsAny("settings", [part, last(parents) || ""])
        ) {
          return baseType === "team" ? (
            <TeamSettingsPage teamId={baseId} />
          ) : (
            <PrivateSettingsPage />
          );
        }

        if (isNesting && !!baseId) {
          return <TeamPage teamId={baseId} />;
        }

        return <EntityPage id={part} />;
      },
    });
  }, [_part, parents?.length]);
}

const ParentRedirect = ({
  entityId,
  type,
}: {
  entityId: string;
  type: EntityType;
}) => {
  const path = usePathName();
  const entity = useLazyEntity(entityId);
  const parentPath = useMemo(() => {
    const parentId = findLast(
      fromScope(entity?.source.scope),
      (t) => maybeTypeFromId(t) === type
    );
    return replace(path, entityId, parentId || "");
  }, [entity?.source.scope, type]);

  if (!entity || !parentPath) {
    return <></>;
  }

  return <Redirect to={parentPath} />;
};
