import { Portal } from "@radix-ui/react-portal";
import { filter as loFilter, find, isString, map } from "lodash";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";

import { ID, Person, Team, Workspace } from "@api";

import { useMe } from "@state/persons";
import { useActiveSpace, useSetSpace } from "@state/spaces";
import { toShortLabel, useLazyAllTeams } from "@state/teams";
import { useActiveWorkspaceId, useCurrentWorkspace } from "@state/workspace";

import { cx } from "@utils/class-names";
import { Handleable, respectHandled, withHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { switchEnum } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import { containsRef } from "@utils/relation-ref";

import { Button, Props as ButtonProps } from "@ui/button";
import { TeamCreateDialog } from "@ui/engine/team";
import { SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowVertical,
  CompanyFilled,
  EyeSlash,
  Icon,
  iconFromString,
  ImageIcon,
  PlusAlt,
  SquareIcon,
  TeamIcon,
} from "@ui/icon";
import { TabBar } from "@ui/tab-bar";
import { Text, TextXLarge } from "@ui/text";
import { WorkspaceSelect } from "@ui/workspace-select";

import { TeamStructureFlow } from "./team-structure-flow";

import styles from "./spaces-overlay.module.css";

export const SpacesOverlay = ({
  onChanged,
  onClose,
}: {
  onChanged: Fn<ID, void>;
  onClose: Fn<void, void>;
}) => {
  const me = useMe();
  const ref = useRef<HTMLDivElement>(null);
  const wID = useActiveWorkspaceId();
  const [filter, setFilter] = useStickyState<"all" | "me">(
    "all",
    "spaces-filter"
  );
  const workspace = useCurrentWorkspace();
  const allTeams = useLazyAllTeams();
  const filteredTeams = useMemo(
    () =>
      filter === "me"
        ? loFilter(allTeams, (t) => containsRef(t.people, me))
        : allTeams,
    [allTeams, filter]
  );

  const space = useActiveSpace();
  const _setSpace = useSetSpace();
  const [selected, setSelected] = useState<Maybe<string>>(space.id);

  const animateOut = useCallback(
    (id: string) => {
      onChanged(id);
      onClose();
    },
    [onChanged]
  );

  const setSpace = useCallback((id: ID) => {
    _setSpace(id);
    setSelected(id);
    animateOut(id);
  }, []);

  const [addingTeam, setAddingTeam] = useState<boolean | string>(false);
  const addingDefaults = useMemo(
    () =>
      !addingTeam
        ? undefined
        : isString(addingTeam)
        ? {
            parent: { id: addingTeam },
            color: find(allTeams, (t) => t.id === addingTeam)?.color,
          }
        : {},
    [addingTeam, allTeams]
  );

  useLayoutEffect(() => {
    setTimeout(() => ref.current?.classList.add(styles.in), 10);
  }, [ref]);

  return (
    <Portal>
      <div
        ref={ref}
        className={cx(styles.container, styles.animation)}
        onClick={respectHandled(() => !addingTeam && animateOut(space.id))}
      >
        {addingDefaults && (
          <div onClick={withHandle(() => {})}>
            <TeamCreateDialog
              defaults={addingDefaults}
              onCancel={() => setAddingTeam(false)}
              onSaved={(t) => setSpace(t.id)}
            />
          </div>
        )}
        <div className={styles.workspaceBar} onClick={withHandle(() => {})}>
          <WorkspaceSelect iconRight={false}>
            <SpaceBetween gap={6}>
              {when(iconFromString(workspace?.icon), (i) => (
                <Icon icon={i} size="small" />
              ))}

              <Text bold className={styles.text}>
                {workspace?.name}
              </Text>
              <Icon
                icon={ArrowVertical}
                size="xsmall"
                className={styles.icon}
              />
            </SpaceBetween>
          </WorkspaceSelect>
        </div>

        <SpaceBetween className={styles.fixedTeams} align="flex-start">
          <SpaceButton
            onClick={withHandle(() => setSpace(wID))}
            entity={workspace}
            icon={
              <SquareIcon className={styles.largeIcon} color="gray_5">
                {when(workspace?.icon, (i) => <ImageIcon url={i} />) || (
                  <Icon icon={CompanyFilled} size="medium" />
                )}
              </SquareIcon>
            }
            selected={selected === wID}
          />

          <div onClick={withHandle(() => {})}>
            <TabBar
              active={filter}
              onActiveChanged={(v) => setFilter(v as "all" | "me")}
              className={styles.tabBar}
              itemClassName={styles.tabBarItem}
              options={[
                { id: "all", title: "All Teams" },
                { id: "me", title: "My Teams" },
              ]}
            />
          </div>

          <SpaceButton
            selected={selected === me.id}
            entity={me}
            onClick={withHandle(() => setSpace(me.id))}
            icon={
              <SquareIcon color="gray_5" className={cx(styles.largeIcon)}>
                <Icon icon={EyeSlash} size="medium" />
              </SquareIcon>
            }
          />
        </SpaceBetween>

        <div className={styles.teams}>
          <TeamStructureFlow
            className={styles.teamsFlow}
            whitelist={map(filteredTeams, (t) => t.id)}
            inverseColors
            mode={filter}
          />
        </div>

        <div className={styles.bottomBar}>
          <Icon
            onClick={withHandle(() => setAddingTeam(true))}
            size="large"
            className={cx(styles.icon, styles.largeIcon, styles.addIcon)}
            icon={
              <SquareIcon color="gray_5">
                <Icon icon={PlusAlt} />
              </SquareIcon>
            }
          />
        </div>
      </div>
    </Portal>
  );
};

export const TeamStack = ({
  team,
  subteams,
  selected,
  onSelected,
  color = "light",
  onAddNew,
}: {
  team: Team;
  onSelected?: Fn<ID, void>;
  onAddNew: Fn<ID, void>;
  color?: "light" | "dark";
  selected?: string;
  subteams: Maybe<Team[]>;
}) => {
  return (
    <VStack
      align="center"
      fit="content"
      className={cx(styles.teamStack, styles[color])}
    >
      <SpaceButton
        entity={team}
        selected={selected === team.id}
        onClick={withHandle(() => onSelected?.(team.id))}
        icon={<TeamIcon team={team} className={styles.largeIcon} />}
      />

      <VStack fit="container" align="center" gap={0}>
        {map(subteams, (sub) => (
          <SpaceButton
            key={sub.id}
            size="medium"
            onClick={withHandle(() => onSelected?.(sub.id))}
            entity={sub}
            selected={selected === sub.id}
            icon={<TeamIcon team={sub} className={styles.mediumIcon} />}
          />
        ))}

        <Icon
          onClick={withHandle(() => onAddNew(team.id))}
          className={cx(styles.icon, styles.mediumIcon, styles.addIcon)}
          size="xlarge"
          icon={
            <SquareIcon color="gray_5">
              <Icon icon={PlusAlt} />
            </SquareIcon>
          }
        />
      </VStack>
    </VStack>
  );
};

export const SpaceButton = ({
  entity,
  selected,
  onClick,
  icon,
  size = "large",
  className,
}: {
  entity?: Maybe<Person | Team | Workspace>;
  size?: "medium" | "large";
  icon: Exclude<ButtonProps["icon"], undefined>;
  selected?: boolean;
  className?: string;
  onClick: Fn<Handleable, void>;
}) => (
  <Button
    subtle
    className={cx(styles.button, styles.team, styles[size], className)}
    onClick={onClick}
  >
    <VStack align="center">
      <Icon
        size={size === "large" ? "xxlarge" : "xlarge"}
        className={cx(
          styles.icon,
          size === "large" && styles.largeIcon,
          size === "medium" && styles.mediumIcon,
          selected && styles.selected
        )}
        icon={icon}
      />
      {size === "large" && (
        <TextXLarge bold className={styles.text}>
          {switchEnum(entity?.source.type || "", {
            team: () => toShortLabel(entity as Team),
            workspace: () => "All Teams",
            person: () => "Private",
            else: () => "",
          })}
        </TextXLarge>
      )}
      {entity && size === "medium" && (
        <Text bold className={styles.text}>
          {toShortLabel(entity as Team)}
        </Text>
      )}
    </VStack>
  </Button>
);
