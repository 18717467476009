import { filter, map, tail } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Person, Team } from "@api";

import { ID } from "@graph";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import {
  useLazyEntities,
  useLazyEntity,
  useUpdateEntity,
} from "@state/generic";
import {
  SystemPackages,
  useHasPackages,
  useInstalledEntities,
} from "@state/packages";
import { useAddToRecents } from "@state/recents";
import { useEntityLabels } from "@state/settings";
import { useSetSpace, useSpaceState } from "@state/spaces";
import {
  useLazyGetTeam,
  useLazyPeopleForTeam,
  useTeamPushTo,
  useToTeamRoute,
} from "@state/teams";
import {
  toTemplateViewId,
  useLazyItemsForView,
  useLazyViewsForFilter,
} from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { respectHandled } from "@utils/event";
import { Fn } from "@utils/fn";
import {
  useQueryParams,
  useShowMore,
  useStickyScroll,
  useStickyState,
} from "@utils/hooks";
import { equalsAny } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { asAppendMutation } from "@utils/property-mutations";
import { plural } from "@utils/string";
import { trimSlashes, useSyncPathnameID } from "@utils/url";

import { BreadcrumbSheet, SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CardHeader } from "@ui/card-header";
import { Centered, Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { render, toEngine } from "@ui/engine";
import { HalfSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowUpRight,
  BacklogFilled,
  BoltFilled,
  Box,
  CalendarFilled,
  ClipboardFilled,
  ClockHistory,
  CompanyFilled,
  ContractFilled,
  DocumentFilled,
  EnvelopeFilled,
  EventFilled,
  ExitLeft,
  FormFilled,
  HomeFilled,
  Icon,
  MilestoneFilled,
  PaintTool,
  PaperPlaneFilled,
  PeopleFilled,
  PersonIconStack,
  PinSlash,
  PipelineFilled,
  PlusAlt,
  PlusIcon,
  ProcessFilled,
  RoadmapFilled,
  TasksFilled,
  WorkflowFilled,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, RouteMenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { CollapsibleMenuItem } from "@ui/menu-item/collapsible";
import { OnHover } from "@ui/on-hover";
import { PackagesMarketplace } from "@ui/packages-markplace";
import { PinnedItems } from "@ui/pinned-section";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { Section } from "@ui/section";
import { GlobalEntitySelect } from "@ui/select";
import { Sheet, SheetProps, StackContainer } from "@ui/sheet-layout";
import { TeamHeader } from "@ui/team-header";
import { TeamPeople as TeamPeopleSection } from "@ui/team-people";
import { Text, TextMedium } from "@ui/text";
import { EntityViewResultsPage } from "@ui/view-results-page";
import WithParams from "@ui/with-params";

import AppPage from "./app-page";
import { TeamMeetingsPage } from "./team-meetings";

import styles from "./team-page.module.css";

interface Props {
  teamId: string;
  viewId?: string;
}

const wrapSheet =
  (Comp: React.FC<Props>, size: SheetProps["size"] = "primary") =>
  ({ teamId }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    useStickyScroll(ref, "primary-sheet");

    return (
      <>
        <SmartBreadcrumbSheet />
        <Sheet ref={ref} size={size} transparency="low" interactable={false}>
          <Comp teamId={teamId} />
        </Sheet>
      </>
    );
  };

const TeamPage = ({ teamId: _teamId }: Props) => {
  const goTo = useGoTo();
  const location = useLocation();
  const params = useQueryParams();
  const team = useLazyEntity(_teamId);
  // Team property/packages do not support saving temp ids, so make sure we're always using the real persisted id
  const teamId = useMemo(() => team?.id || _teamId, [_teamId, team?.id]);
  const [lastTeamPage, setLastTeamPage] = useStickyState<Maybe<string>>(
    undefined,
    `team-${teamId}-last-page`
  );
  const childRoute = useMemo(
    () => ({
      pathname:
        "/" + tail(trimSlashes(location.pathname).split("/"))?.join("/"),
    }),
    [location]
  );
  const [page] = useRegisterPage(teamId, team);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(_teamId, team?.id);

  // Add team to recently viewed
  useAddToRecents(teamId);

  const toRoute = useToTeamRoute(teamId);
  const isOverview = useMemo(
    () => location.pathname === toRoute() || location.pathname === toRoute("/"),
    [location.pathname]
  );

  useEffect(() => {
    if (params.goTo === "last" && isOverview && lastTeamPage) {
      goTo(lastTeamPage, {}, { replace: true });
    }
  }, []);

  useEffect(() => {
    setLastTeamPage(location.pathname);
  }, [setLastTeamPage, location.pathname, isOverview]);

  if (params.goTo === "last") {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <Routes location={childRoute}>
          <Route path="/" element={<TeamOverview teamId={teamId} />} />

          <Route
            path="/people"
            element={team && <TeamPeople teamId={teamId} />}
          />

          <Route path="/projects" element={<TeamProjects teamId={teamId} />} />

          <Route path="/backlogs" element={<TeamBacklogs teamId={teamId} />} />

          <Route
            path="/pipelines"
            element={<TeamPipelines teamId={teamId} />}
          />

          <Route path="/roadmaps" element={<TeamRoadmaps teamId={teamId} />} />

          <Route
            path="/calendars"
            element={<TeamCalendars teamId={teamId} />}
          />

          <Route
            path="/campaigns/*"
            element={<TeamCampaigns teamId={teamId} />}
          />

          <Route path="/sprints" element={<TeamSprints teamId={teamId} />} />

          <Route path="/tasks/*" element={<TeamTasks teamId={teamId} />} />

          <Route
            path="/meetings/*"
            element={<TeamMeetingsPage teamId={teamId} />}
          />

          <Route path="/pages/*" element={<TeamPages teamId={teamId} />} />

          <Route
            path="/workflows/*"
            element={<TeamWorkflows teamId={teamId} />}
          />

          <Route
            path="/outcomes/*"
            element={<TeamOutcomes teamId={teamId} />}
          />

          <Route path="/deals/*" element={<TeamDeals teamId={teamId} />} />

          <Route path="/contents/*" element={<TeamContent teamId={teamId} />} />

          <Route path="/events/*" element={<TeamEvent teamId={teamId} />} />

          <Route
            path="/processes"
            element={<TeamProcesses teamId={teamId} />}
          />

          <Route path="/forms" element={<TeamForms teamId={teamId} />} />

          <Route
            path="/:viewId"
            element={
              <WithParams
                params={({ viewId }) => ({ teamId, viewId })}
                component={TeamTasks}
              />
            }
          />
        </Routes>
      </StackContainer>
    </AppPage>
  );
};

const TeamOverview = ({ teamId }: Props) => {
  const pushTo = useTeamPushTo(teamId);
  const goTo = useGoTo();
  const wId = useActiveWorkspaceId();
  const team = useLazyGetTeam(teamId);
  const [space, setSpace] = useSpaceState();
  const mutate = useUpdateEntity(teamId);
  const people = useLazyPeopleForTeam(teamId);
  const pinned = useLazyEntities(team?.refs?.pins || []);
  const members = useMemo(
    () => filter(people, (p) => p.id !== team?.owner?.id),
    [people, team?.owner?.id]
  );
  const toLabel = useEntityLabels(teamId);
  const installed = useInstalledEntities(teamId);

  useEffect(() => {
    if (space.id !== teamId) {
      setSpace(teamId);
    }
  }, [teamId]);

  if (!team) {
    return <></>;
  }

  return (
    <>
      <BreadcrumbSheet
        links={[{ text: "All teams" }]}
        onClick={() => {
          setSpace(wId);
          goTo("/teams");
        }}
      />

      <Sheet size="primary-thicc">
        <Container size="double">
          <VStack gap={40} fit="container">
            <VStack gap={20}>
              <TeamHeader team={team} />

              <Card onClick={respectHandled(() => pushTo("/people"))}>
                <SpaceBetween>
                  <HStack>
                    {team.owner && (
                      <Icon
                        icon={<RelationIcon relation={team.owner} />}
                        size="xlarge"
                      />
                    )}
                    <PersonIconStack people={members} onSelected={goTo} />
                  </HStack>
                  <Button subtle iconRight={ArrowUpRight}>
                    <Text subtle>Manage</Text>
                  </Button>
                </SpaceBetween>
              </Card>
            </VStack>

            <Divider />

            <Container
              padding="none"
              fit="container"
              stack="horizontal"
              gap={20}
              wrap
              align="stretch"
            >
              <PinnedItems
                items={pinned || []}
                onOpen={goTo}
                onPin={(r) =>
                  mutate(
                    asAppendMutation(
                      { field: "refs.pins", type: "relations" },
                      [r]
                    )
                  )
                }
                onUnpin={(r) =>
                  mutate(
                    asAppendMutation(
                      { field: "refs.pins", type: "relations" },
                      [r],
                      "remove"
                    )
                  )
                }
                scope={team.id}
              />

              {equalsAny("meeting", installed) && (
                <TeamOverviewCard
                  viewId={toTemplateViewId("today-meetings", {
                    parent: teamId,
                  })}
                  label={plural(toLabel("meeting"))}
                  onClick={() => pushTo("/meetings")}
                  height="content"
                />
              )}

              {equalsAny("form", installed) && (
                <TeamOverviewCard
                  viewId={toTemplateViewId("team-form", {
                    parent: teamId,
                  })}
                  templates={true}
                  label={plural(toLabel("form"))}
                  onClick={() => pushTo("/forms")}
                  height="content"
                />
              )}

              {equalsAny("process", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-process", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("process"))}
                  />
                </HalfSpace>
              )}

              {equalsAny("roadmap", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-roadmap", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("roadmap"))}
                    onClick={() => pushTo("/roadmaps")}
                  />
                </HalfSpace>
              )}

              {equalsAny("calendar", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-calendar", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("calendar"))}
                    onClick={() => pushTo("/calendars")}
                  />
                </HalfSpace>
              )}

              {equalsAny("campaign", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-campaign", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("campaign"))}
                    onClick={() => pushTo("/campaigns")}
                  />
                </HalfSpace>
              )}

              {equalsAny("backlog", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-backlog", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("backlog"))}
                    onClick={() => pushTo("/backlogs")}
                  />
                </HalfSpace>
              )}

              {equalsAny("pipeline", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-pipeline", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("pipeline"))}
                    onClick={() => pushTo("/pipelines")}
                  />
                </HalfSpace>
              )}

              {equalsAny("sprint", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-sprint", { parent: teamId })}
                    label={plural(toLabel("sprint"))}
                    onClick={() => pushTo("/sprints")}
                  />
                </HalfSpace>
              )}

              {equalsAny("project", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-project", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("project"))}
                    onClick={() => pushTo("/projects")}
                  />
                </HalfSpace>
              )}

              {equalsAny("page", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-page", { parent: teamId })}
                    label={plural(toLabel("page"))}
                    onClick={() => pushTo("/pages")}
                  />
                </HalfSpace>
              )}

              {equalsAny("deal", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-deal", { parent: teamId })}
                    label={plural(toLabel("deal"))}
                    onClick={() => pushTo("/deals")}
                  />
                </HalfSpace>
              )}

              {equalsAny("outcome", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-outcome", {
                      parent: teamId,
                    })}
                    label={plural(toLabel("outcome"))}
                  />
                </HalfSpace>
              )}

              {equalsAny("workflow", installed) && (
                <HalfSpace gap={20}>
                  <TeamOverviewCard
                    viewId={toTemplateViewId("team-workflow", {
                      parent: teamId,
                    })}
                    label={"Running " + plural(toLabel("workflow"))}
                    onClick={() => pushTo("/workflows")}
                  />
                </HalfSpace>
              )}

              <Section title="Browse">
                <HStack wrap>
                  {equalsAny("task", installed) && (
                    <Button
                      icon={TasksFilled}
                      onClick={() =>
                        pushTo(
                          toTemplateViewId("team-task", { parent: teamId })
                        )
                      }
                    >
                      {plural(toLabel("task"))}
                    </Button>
                  )}
                  {equalsAny("contact", installed) && (
                    <Button
                      icon={PeopleFilled}
                      onClick={() =>
                        pushTo(
                          toTemplateViewId("team-contact", { parent: teamId })
                        )
                      }
                    >
                      {plural(toLabel("contact"))}
                    </Button>
                  )}
                  {equalsAny("company", installed) && (
                    <Button
                      icon={CompanyFilled}
                      onClick={() =>
                        pushTo(
                          toTemplateViewId("team-company", { parent: teamId })
                        )
                      }
                    >
                      {plural(toLabel("company"))}
                    </Button>
                  )}
                </HStack>
              </Section>
            </Container>
          </VStack>
        </Container>
      </Sheet>
    </>
  );
};

const TeamProjects = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="project" />;
});

const TeamBacklogs = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="backlog" />;
});

const TeamPipelines = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="pipeline" />;
});

const TeamRoadmaps = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="roadmap" />;
});

const TeamCalendars = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="calendar" />;
});

const TeamSprints = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="sprint" />;
});

const TeamProcesses = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="process" />;
});

const TeamPeople = wrapSheet(({ teamId }: Props) => {
  const team = useLazyGetTeam(teamId);
  const mutate = useUpdateEntity(teamId);

  if (!team) {
    return <></>;
  }

  return (
    <Container>
      <Centered fit="container" stack="vertical" gap={20}>
        <TeamPeopleSection team={team} mutate={mutate} />
      </Centered>
    </Container>
  );
});

const TeamOverviewCard = ({
  viewId,
  label,
  height = "container",
  onClick,
  templates,
}: {
  label?: string;
  viewId: ID;
  height?: "content" | "container";
  onClick?: Fn<void, void>;
  templates?: boolean;
}) => {
  const items = useLazyItemsForView(viewId, { templates });
  const pushTo = usePushTo();
  const { visible, hasMore, moreCount } = useShowMore(
    items.items.sorted || items?.items.all,
    5
  );
  return (
    <Card
      onClick={respectHandled(() => (onClick ? onClick() : pushTo(viewId)))}
      height={height}
    >
      <CardHeader padding="none" border={false}>
        <TextMedium bold>{label}</TextMedium>
      </CardHeader>

      <Menu>
        <MenuGroup>
          {map(visible, (o) =>
            render(toEngine(o)?.asMenuItem, {
              key: o.id,
              item: o,
              onOpen: pushTo,
            })
          )}
          {hasMore && <ShowMoreMenuItem text="Show all" count={moreCount} />}
        </MenuGroup>
      </Menu>
    </Card>
  );
};

const TeamWorkflows = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="workflow" />;
});

const TeamPages = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="page" />;
});

const TeamTasks = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="task" />;
});

const TeamCampaigns = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="campaign" />;
});

const TeamContent = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="content" />;
});

const TeamOutcomes = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="outcome" />;
});

const TeamDeals = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="deal" />;
});

const TeamEvent = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="event" />;
});

const TeamForms = wrapSheet(({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="form" />;
});

export default TeamPage;

export const TeamMenu = ({
  team,
  groupLabel,
  showPinned = true,
  collapsed = false,
}: {
  team: Team | Person;
  groupLabel?: string;
  showPinned?: boolean;
  collapsed?: boolean;
}) => {
  const teamId = team.id;
  const wID = useActiveWorkspaceId();
  const setSpace = useSetSpace();
  const pushTo = useTeamPushTo(teamId);
  const toRoute = useToTeamRoute(teamId);
  const mutate = useUpdateEntity(teamId);
  const toLabel = useEntityLabels(teamId);
  const pinned = useLazyEntities(team?.refs?.pins || []);
  const [marketplaceShowing, showMarketplace] = useState(false);

  // TODO: Fix this up....
  const outcomeViews = useLazyViewsForFilter(
    { location: teamId, for: teamId, type: "outcome" },
    false
  );
  const taskViews = useLazyViewsForFilter(
    { location: teamId, for: teamId, type: "task" },
    false
  );
  const contentViews = useLazyViewsForFilter(
    { location: teamId, for: teamId, type: "content" },
    false
  );
  const eventViews = useLazyViewsForFilter(
    { location: teamId, for: teamId, type: "event" },
    false
  );

  const installed = useHasPackages(teamId, [
    SystemPackages.Campaigns,
    SystemPackages.Meetings,
    SystemPackages.Calendars,
    SystemPackages.Projects,
    SystemPackages.Content,
    SystemPackages.Backlogs,
    SystemPackages.Pipelines,
    SystemPackages.Tasks,
    SystemPackages.Roadmaps,
    SystemPackages.Outcomes,
    SystemPackages.Sprints,
    SystemPackages.Pages,
    SystemPackages.Processes,
    SystemPackages.Forms,
    SystemPackages.Events,
    SystemPackages.Companys,
    SystemPackages.Contacts,
    SystemPackages.Deals,
    SystemPackages.Workflows,
  ]);

  return (
    <OnHover.Trigger>
      <SpaceBetween direction="vertical" width="container">
        <Menu>
          {marketplaceShowing && (
            <PackagesMarketplace
              scope={teamId}
              onClose={() => showMarketplace(false)}
            />
          )}

          <MenuGroup
            className={styles.menuGroup}
            label={groupLabel}
            actions={
              <OnHover.Target>
                <Button
                  icon={ExitLeft}
                  iconSize="small"
                  subtle
                  size="tiny"
                  onClick={() => setSpace?.(wID)}
                />
              </OnHover.Target>
            }
          >
            {team.source.type === "team" && (
              <RouteMenuItem
                text="Home"
                icon={HomeFilled}
                route={toRoute("/")}
                collapsed={collapsed}
              />
            )}

            {team.source.type === "team" && (
              <RouteMenuItem
                icon={PeopleFilled}
                text={plural(toLabel("person"))}
                route={toRoute("/people")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Meetings] && (
              <RouteMenuItem
                icon={CalendarFilled}
                text={plural(toLabel("meeting"))}
                route={toRoute("/meetings")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Forms] && (
              <RouteMenuItem
                icon={FormFilled}
                text="Forms"
                route={toRoute("/forms")}
                collapsed={collapsed}
              />
            )}
          </MenuGroup>

          <MenuGroup label="Knowledge">
            {installed[SystemPackages.Processes] && (
              <RouteMenuItem
                icon={ProcessFilled}
                text={plural(toLabel("process"))}
                route={toRoute("/processes")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Pages] && (
              <RouteMenuItem
                text={plural(toLabel("page"))}
                icon={DocumentFilled}
                route={toRoute("/pages")}
                collapsed={collapsed}
              />
            )}
          </MenuGroup>

          {showPinned && (
            <MenuGroup label="Pinned" className={styles.menuGroup}>
              {map(pinned, (p) => (
                <ContextMenu
                  key={p.id}
                  actions={
                    <>
                      <ContextItem
                        icon={PinSlash}
                        onClick={() =>
                          mutate(
                            asAppendMutation(
                              {
                                field: "refs.pins",
                                type: "relations",
                              },
                              [p],
                              "remove"
                            )
                          )
                        }
                      >
                        Unpin from team
                      </ContextItem>
                    </>
                  }
                >
                  <MenuItem iconRight={ArrowUpRight} onClick={() => pushTo(p)}>
                    <RelationLabel relation={p} />
                  </MenuItem>
                </ContextMenu>
              ))}

              <GlobalEntitySelect
                portal={true}
                scope={team?.id}
                value={undefined}
                allowed={"*"}
                onChange={(p) =>
                  p &&
                  mutate(
                    asAppendMutation(
                      { field: "refs.pins", type: "relations" },
                      [p]
                    )
                  )
                }
              >
                <MenuItem icon={PlusIcon}>
                  <Text subtle>Add pin</Text>
                </MenuItem>
              </GlobalEntitySelect>
            </MenuGroup>
          )}

          <MenuGroup label="Organisation" className={styles.menuGroup}>
            {installed[SystemPackages.Roadmaps] && (
              <RouteMenuItem
                icon={RoadmapFilled}
                text={plural(toLabel("roadmap"))}
                route={toRoute("/roadmaps")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Calendars] && (
              <RouteMenuItem
                icon={CalendarFilled}
                text={plural(toLabel("calendar"))}
                route={toRoute("/calendars")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Campaigns] && (
              <RouteMenuItem
                icon={PaperPlaneFilled}
                text={plural(toLabel("campaign"))}
                route={toRoute("/campaigns")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Backlogs] && (
              <RouteMenuItem
                icon={BacklogFilled}
                text={plural(toLabel("backlog"))}
                route={toRoute("/backlogs")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Pipelines] && (
              <RouteMenuItem
                icon={PipelineFilled}
                text={plural(toLabel("pipeline"))}
                route={toRoute("/pipelines")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Projects] && (
              <RouteMenuItem
                icon={ClipboardFilled}
                text={plural(toLabel("project"))}
                route={toRoute("/projects")}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Sprints] && (
              <RouteMenuItem
                icon={BoltFilled}
                text={plural(toLabel("sprint"))}
                route={toRoute("/sprints")}
                collapsed={collapsed}
              />
            )}
          </MenuGroup>

          <MenuGroup label="Browse" className={styles.menuGroup}>
            {installed[SystemPackages.Workflows] && (
              <RouteMenuItem
                icon={WorkflowFilled}
                text="Workflows"
                route={toRoute("/workflows")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Deals] && (
              <RouteMenuItem
                icon={ContractFilled}
                text={plural(toLabel("deal"))}
                iconRight={ArrowUpRight}
                route={toRoute(
                  toTemplateViewId("team-deal", { parent: teamId })
                )}
                collapsed={collapsed}
              />
            )}
            {installed[SystemPackages.Contacts] && (
              <RouteMenuItem
                icon={PeopleFilled}
                text={plural(toLabel("contact"))}
                iconRight={ArrowUpRight}
                collapsed={collapsed}
                route={toRoute(
                  toTemplateViewId("team-contact", {
                    parent: teamId,
                  })
                )}
              />
            )}
            {installed[SystemPackages.Companys] && (
              <RouteMenuItem
                icon={CompanyFilled}
                text={plural(toLabel("company"))}
                iconRight={ArrowUpRight}
                collapsed={collapsed}
                route={toRoute(
                  toTemplateViewId("team-company", {
                    parent: teamId,
                  })
                )}
              />
            )}

            {installed[SystemPackages.Outcomes] && (
              <RouteMenuItem
                icon={MilestoneFilled}
                text={plural(toLabel("outcome"))}
                iconRight={ArrowUpRight}
                route={toRoute(outcomeViews[0]?.id || "/outcomes")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Content] && (
              <RouteMenuItem
                icon={EnvelopeFilled}
                text={plural(toLabel("content"))}
                iconRight={ArrowUpRight}
                route={toRoute(contentViews?.[0]?.id || "/contents")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Events] && (
              <RouteMenuItem
                icon={EventFilled}
                text={plural(toLabel("event"))}
                iconRight={ArrowUpRight}
                route={toRoute(eventViews?.[0]?.id || "/events")}
                collapsed={collapsed}
              />
            )}

            {installed[SystemPackages.Tasks] && (
              <RouteMenuItem
                icon={TasksFilled}
                text={plural(toLabel("task"))}
                iconRight={ArrowUpRight}
                route={toRoute(taskViews?.[0]?.id || "/tasks")}
                collapsed={collapsed}
              />
            )}
          </MenuGroup>

          <MenuGroup>
            <CollapsibleMenuItem
              icon={PlusAlt}
              iconSize="xsmall"
              onClick={() => showMarketplace(true)}
              collapsed={collapsed}
            >
              <Text subtle>Install</Text>
            </CollapsibleMenuItem>
          </MenuGroup>
        </Menu>

        <Menu>
          <MenuGroup label="Operations">
            <RouteMenuItem
              icon={PaintTool}
              text="Templates"
              route={toRoute("/settings/templates")}
              collapsed={collapsed}
            />
            <RouteMenuItem
              icon={ClockHistory}
              text="Recurring"
              route={toRoute("/settings/schedules")}
              collapsed={collapsed}
            />
            <RouteMenuItem
              icon={Box}
              text="Packages"
              route={toRoute("/settings/fields")}
              collapsed={collapsed}
            />
          </MenuGroup>
        </Menu>
      </SpaceBetween>
    </OnHover.Trigger>
  );
};
