import { filter, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";

import { ID, isTeam, Person, Team } from "@api";

import { useOpenAppCommands, useOpenRecents } from "@state/app";
import { GlobalFetchOptionsAtom, showArchived } from "@state/fetch-results";
import {
  useLazyEntities,
  useLazyEntity,
  useLazyQuery,
  useLogout,
  useUpdateEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useSpaceState } from "@state/spaces";
import { toManagePackages, useLazyAllTeams, useTeamPushTo } from "@state/teams";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { dependencySort } from "@utils/array";
import { cx } from "@utils/class-names";
import { useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { useStickyState } from "@utils/hooks";
import { isTeamId, maybeTypeFromId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { maybeMap, safeAs, when } from "@utils/maybe";
import { useGoTo, usePathName } from "@utils/navigation";
import { asAppendMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { toParentScope, toRouterScope } from "@utils/scope";

import { ActionItem, ActionMenu } from "@ui/action-menu";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { Divider } from "@ui/divider";
import { Ellipsis } from "@ui/ellipsis";
import { TeamCreateDialog } from "@ui/engine/team";
import { ViewCreateDialog } from "@ui/engine/view";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  Archive,
  ArchiveOpen,
  ArrowLeft,
  ClockHistory,
  Cog,
  CompanyFilled,
  EmojiIcon,
  EyeSlash,
  Icon,
  PersonIcon,
  PinSlash,
  PlusIcon,
  Search,
  SpaceIcon,
  SquareIcon,
  StatusLive,
  TeamIcon,
  ViewIcon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem } from "@ui/menu-item";
import { CollapsibleMenuItem } from "@ui/menu-item/collapsible";
import { ExpandableMenuItem } from "@ui/menu-item/expandable";
import { TeamMenu } from "@ui/page/team-page";
import { GlobalEntitySelect } from "@ui/select";
import { SpacesSelect } from "@ui/spaces-select";
import { Text } from "@ui/text";

import { SmartDefaultsAddWorkDialog } from "./add-work-dialog";
import { RelationIcon, RelationLabel } from "./relation-label";

import styles from "./primary-nav.module.css";

function PrimaryNavInner({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Fn<boolean, void>;
}) {
  const goTo = useGoTo();
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const path = useLocation();
  const fullMe = useLazyEntity<"person">(me.id);
  const pinned = useLazyEntities(fullMe?.refs?.pins);
  const allTeams = useLazyAllTeams();
  const [fetchOptions, setFetchOptions] = useRecoilState(
    GlobalFetchOptionsAtom
  );
  const mutate = useUpdateEntity(me.id);
  const [dialog, setDialog] = useState<
    "view" | "team" | "add-work" | "space"
  >();
  const [showAll, setShowAll] = useStickyState<boolean>(
    false,
    "nav-show-all-teams"
  );

  const [space, setSpace] = useSpaceState();
  const openSearch = useOpenAppCommands();
  const openRecents = useOpenRecents();

  const teams = useMemo(() => {
    const filtered = showAll
      ? allTeams
      : filter(allTeams, (t) => containsRef(t.people, me));
    return dependencySort(filtered, (t) => t.parent?.id);
  }, [allTeams, showAll]);

  const logout = useLogout();
  const toggleCollapsed = useCallback(
    () => setCollapsed(!collapsed),
    [collapsed]
  );

  const goToSpace = useCallback(
    (id: string) => {
      setSpace(id);
      goTo(isTeamId(id) ? id : "/home");
    },
    [setSpace, goTo]
  );

  useShortcut({ command: true, key: "Backslash" }, toggleCollapsed, [
    toggleCollapsed,
  ]);

  return (
    <div className={cx(styles.root, collapsed && styles.collapsed)}>
      {dialog === "team" && (
        <TeamCreateDialog
          defaults={{}}
          onCancel={() => setDialog(undefined)}
          onSaved={(t) => {
            setDialog(undefined);
            goTo(toManagePackages(t.id));
          }}
        />
      )}
      {dialog === "view" && (
        <ViewCreateDialog
          defaults={{}}
          onCancel={() => setDialog(undefined)}
          onSaved={(view) => {
            goTo(view);
            setDialog(undefined);
          }}
        />
      )}
      {dialog === "add-work" && (
        <SmartDefaultsAddWorkDialog
          onCancel={() => setDialog(undefined)}
          onSaved={(tasks) => {
            goTo(tasks);
            setDialog(undefined);
          }}
        />
      )}

      <SpacesSelect collapsed={collapsed} onCollapse={toggleCollapsed} />

      <Container
        fit="container"
        padding="none"
        stack="vertical"
        className={cx(styles.newSearch)}
        align="center"
        gap={10}
      >
        {space.mode !== "workspace" && (
          <Menu className={styles.backMenu}>
            <MenuGroup>
              <CollapsibleMenuItem
                collapsed={collapsed}
                icon={ArrowLeft}
                onClick={() => goToSpace(wId)}
              >
                <Text subtle>All teams</Text>
              </CollapsibleMenuItem>
            </MenuGroup>
          </Menu>
        )}

        <Divider />

        <SpaceBetween
          gap={6}
          direction={collapsed ? "vertical" : "horizontal"}
          width="container"
        >
          <Button
            subtle
            fit="content"
            size="small"
            className={cx(styles.primaryAction, styles.searchButton)}
            onClick={openSearch}
          >
            <Icon icon={Search} />
          </Button>
          {!collapsed && (
            <>
              <Button
                subtle
                fit="content"
                size="small"
                className={cx(styles.primaryAction, styles.searchButton)}
                onClick={openRecents}
              >
                <Icon icon={ClockHistory} />
              </Button>
              <Button
                variant="primary"
                fit="content"
                size="small"
                className={cx(styles.primaryAction, styles.newButton)}
                onClick={() => setDialog("add-work")}
              >
                <Icon icon={PlusIcon} className={styles.icon} />
              </Button>
            </>
          )}
        </SpaceBetween>

        <CurrentMeetingButton />

        <Divider />
      </Container>

      <VStack className={styles.fill} fit="container" gap={20}>
        {space.mode === "workspace" && (
          <Menu>
            <MenuGroup>
              <CollapsibleMenuItem
                className={styles.menuItem}
                collapsed={collapsed}
                onClick={() => goTo("/home")}
                icon={<PersonIcon person={me} />}
                selected={path?.pathname?.includes("/home")}
              >
                My Work
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                collapsed={collapsed}
                onClick={() => goTo("/inbox")}
                icon={<EmojiIcon emoji="📥" />}
                selected={path?.pathname?.includes("/inbox")}
              >
                Inbox
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                collapsed={collapsed}
                onClick={() => goTo("/outbox")}
                icon={<EmojiIcon emoji="📤" />}
                selected={path?.pathname?.includes("/outbox")}
              >
                Follow-up
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                collapsed={collapsed}
                icon={<ViewIcon layout={"list"} />}
                onClick={() =>
                  goTo([
                    "boards",
                    toTemplateViewId("my-tasks", {
                      parent: me.id,
                      entity: "task",
                    }),
                  ])
                }
                selected={path?.pathname?.startsWith("/boards")}
              >
                My Boards
              </CollapsibleMenuItem>

              <CollapsibleMenuItem
                className={styles.menuItem}
                collapsed={collapsed}
                onClick={() => goTo(["meetings"])}
                icon={<EmojiIcon emoji="🤝" />}
                selected={path?.pathname?.startsWith("/meetings")}
              >
                My Meetings
              </CollapsibleMenuItem>
            </MenuGroup>

            <MenuGroup label={pinned?.length ? "My Pins" : undefined}>
              {maybeMap(pinned, (v) => (
                <ContextMenu
                  key={v.id}
                  actions={
                    <ContextItem
                      icon={PinSlash}
                      onClick={() =>
                        mutate(
                          asAppendMutation(
                            { field: "refs.pins", type: "relations" },
                            [toRef(v)],
                            "remove"
                          )
                        )
                      }
                    >
                      Unpin
                    </ContextItem>
                  }
                >
                  <CollapsibleMenuItem
                    collapsed={collapsed}
                    className={styles.menuItem}
                    onClick={() => goTo(v)}
                    icon={<RelationIcon relation={v} />}
                  >
                    <SpaceBetween>
                      <RelationLabel icon={false} relation={v} />
                    </SpaceBetween>
                  </CollapsibleMenuItem>
                </ContextMenu>
              ))}

              <GlobalEntitySelect
                value={undefined}
                onChange={(r) =>
                  r &&
                  mutate(
                    asAppendMutation(
                      { field: "refs.pins", type: "relations" },
                      [r]
                    )
                  )
                }
                type="team"
                allowed="*"
                showOtherTeams={true}
              >
                <CollapsibleMenuItem
                  icon={PlusIcon}
                  text="Add Pin"
                  collapsed={collapsed}
                />
              </GlobalEntitySelect>
            </MenuGroup>
            <MenuGroup
              label={showAll || collapsed ? "Teams" : "My Teams"}
              onLabelClicked={() => {
                setShowAll(!showAll);
                setCollapsed(false);
              }}
            >
              {map(teams, (t) => (
                <TeamMenuItem
                  key={t.id}
                  team={t}
                  highlighted={path?.pathname?.includes(t.id)}
                  collapsed={collapsed}
                  onEnterSpace={goToSpace}
                />
              ))}

              <TeamMenuItem
                team={me}
                onEnterSpace={goToSpace}
                collapsed={collapsed}
              />

              <CollapsibleMenuItem
                icon={<Icon icon={CompanyFilled} />}
                collapsed={collapsed}
                onClick={() => goTo("/teams")}
                selected={path?.pathname?.includes("/teams")}
              >
                All Teams
              </CollapsibleMenuItem>

              {!teams.length && <EmptyMenuItem text="No teams." />}
            </MenuGroup>
          </Menu>
        )}

        {space.mode === "team" &&
          when(safeAs<Team>(space.entity), (team) => (
            <TeamMenu
              team={team}
              // groupLabel="Team"
              showPinned={false}
              collapsed={collapsed}
            />
          ))}

        {space.mode === "person" &&
          when(safeAs<Person>(space.entity), (person) => (
            <TeamMenu
              team={person}
              // groupLabel="Private"
              showPinned={false}
              collapsed={collapsed}
            />
          ))}
      </VStack>

      <Container className={cx(styles.bottom)} padding="none">
        <Menu>
          <MenuGroup>
            <Divider className={styles.divider} />

            <CollapsibleMenuItem
              className={cx(
                styles.menuItem,
                styles.archived,
                fetchOptions?.archived && styles.open
              )}
              onClick={() =>
                setFetchOptions(showArchived(!fetchOptions?.archived))
              }
              icon={fetchOptions?.archived ? ArchiveOpen : Archive}
              collapsed={collapsed}
            >
              {fetchOptions?.archived ? "Close archives" : "Open archives"}
            </CollapsibleMenuItem>

            {equalsAny(space.mode, ["team"]) ? (
              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={Cog}
                collapsed={collapsed}
                onClick={() => goTo(`/${space.id}/settings`)}
              >
                Settings
              </CollapsibleMenuItem>
            ) : (
              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={Cog}
                collapsed={collapsed}
                onClick={() => goTo("/settings/personal")}
              >
                Settings
              </CollapsibleMenuItem>
            )}

            <ActionMenu
              actions={
                <>
                  <ActionItem
                    className={styles.menuItem}
                    onClick={() => logout()}
                    icon={<EmojiIcon emoji="👋" />}
                  >
                    Logout
                  </ActionItem>
                </>
              }
            >
              <CollapsibleMenuItem
                className={styles.menuItem}
                icon={me && <PersonIcon person={me} />}
                collapsed={collapsed}
              >
                {me?.name}
              </CollapsibleMenuItem>
            </ActionMenu>
          </MenuGroup>
        </Menu>
      </Container>
    </div>
  );
}

interface TeamMenuProps {
  team: Team | Person;
  highlighted?: boolean;
  collapsed?: boolean;
  onEnterSpace?: Fn<ID, void>;
}

export const TeamMenuItem = ({
  team,
  collapsed,
  highlighted,
  onEnterSpace,
}: TeamMenuProps) => {
  const goTo = useGoTo();
  const pushTo = useTeamPushTo(team.id);

  if (!team) {
    return <></>;
  }

  if (collapsed) {
    return (
      <CollapsibleMenuItem
        selected={highlighted}
        className={cx(styles.menuItem)}
        collapsed={true}
        icon={
          isTeam(team) ? (
            <TeamIcon team={team} />
          ) : (
            <SquareIcon color="gray_5">
              <EyeSlash />
            </SquareIcon>
          )
        }
        onClick={() => onEnterSpace?.(team.id)}
      >
        <Text>{isTeam(team) ? team.name : "Private"}</Text>
      </CollapsibleMenuItem>
    );
  }

  return (
    <ExpandableMenuItem
      saveKey={team.id}
      selected={highlighted}
      className={cx(
        styles.menuItem,
        styles.teamItem,
        styles.onHover,
        styles.team
      )}
      onClick={() => onEnterSpace?.(team.id)}
      size="medium"
      exapandable={!!team?.refs?.pins?.length}
      highlight="shadow"
      icon={
        <Icon
          className={styles.teamIcon}
          icon={<SpaceIcon entity={team} />}
          size="medium"
        />
      }
      text={isTeam(team) ? team.name : "Private"}
    >
      <>
        {map(team?.refs?.pins, (r) => (
          <CollapsibleMenuItem
            key={r.id}
            collapsed={collapsed ?? false}
            indent={1}
            onClick={() => pushTo(r)}
            icon={<RelationIcon relation={r} />}
          >
            <RelationLabel icon={false} relation={r} />
          </CollapsibleMenuItem>
        ))}
      </>
    </ExpandableMenuItem>
  );
};

const CurrentMeetingButton = () => {
  const goTo = useGoTo();
  const me = useMe();

  const [current] = useLazyQuery(
    "meeting",
    useMemo(
      () => ({
        and: [
          {
            field: "status",
            type: "status",
            op: "equals",
            value: { status: { group: "in-progress" } },
          },
          {
            field: "refs.people",
            type: "relations",
            op: "equals",
            value: { relations: [toRef(me)] },
          },
        ],
      }),
      [me?.id]
    ),
    { limit: 1 }
  );

  return useMemo(
    () =>
      !!current && (
        <Button
          subtle
          variant="danger"
          fit="content"
          size="small"
          className={cx(styles.meetingButton)}
          onClick={() => goTo(current)}
        >
          <HStack fit="container" gap={4} className={styles.text}>
            <Icon icon={StatusLive} />
            <Ellipsis>{current.name}</Ellipsis>
          </HStack>
        </Button>
      ),
    [current?.id, current?.name]
  );
};

export default function PrimaryNav() {
  const [hovering, setHovering] = useState(false);
  const pathName = usePathName();
  const [collapsedState, setCollapsed] = useStickyState<boolean>(
    false,
    "primary-nav-open"
  );
  const autoCollapse = useMemo(
    () =>
      equalsAny(maybeTypeFromId(toRouterScope(pathName)), [
        "calendar",
        "roadmap",
        "sprint",
        "backlog",
        "project",
        "pipeline",
        "campaign",
      ]) ||
      equalsAny(maybeTypeFromId(toParentScope(pathName)), ["view"]) ||
      pathName?.includes("/settings/"),
    [pathName]
  );
  const collapsed = autoCollapse || collapsedState;

  if (!collapsed) {
    return (
      <PrimaryNavInner collapsed={collapsed} setCollapsed={setCollapsed} />
    );
  }

  return (
    <div
      className={styles.hoverContainer}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => setHovering(false)}
    >
      <PrimaryNavInner collapsed={true} setCollapsed={setCollapsed} />;
      <div className={cx(styles.overlay, hovering && styles.hovering)}>
        <PrimaryNavInner collapsed={false} setCollapsed={setCollapsed} />;
      </div>
    </div>
  );
}
