// [[READONLY GENERATED FILE]] THIS HAS BEEN GENERATED FROM THE GRAPHQL SCHEMA.GQL FILE.
/* eslint-disable */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { RequestContext } from './context';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  HexCode: string;
  JSON: any;
  Time: Date;
  URL: string;
};

export type Action = {
  __typename?: 'Action';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  assigned?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  open?: Maybe<Scalars['Boolean']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Agenda = {
  __typename?: 'Agenda';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  code?: Maybe<Scalars['ID']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  notes?: Maybe<RichText>;
  open?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum AiAssist {
  Auto = 'auto',
  Manual = 'manual',
  Off = 'off'
}

export type Auth = {
  __typename?: 'Auth';
  bot?: Maybe<AuthDetails>;
  source: Integration;
  user?: Maybe<AuthDetails>;
  workspace?: Maybe<AuthDetails>;
};

export type AuthDetails = {
  __typename?: 'AuthDetails';
  authedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['DateTime']>;
};

export enum AuthScope {
  Person = 'person',
  Workspace = 'workspace'
}

export type Auths = {
  __typename?: 'Auths';
  asana?: Maybe<Auth>;
  figma?: Maybe<Auth>;
  github?: Maybe<Auth>;
  google?: Maybe<Auth>;
  notion?: Maybe<Auth>;
  slack?: Maybe<Auth>;
  traction?: Maybe<Auth>;
  zoom?: Maybe<Auth>;
};

export type Backlog = {
  __typename?: 'Backlog';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  fields?: Maybe<Scalars['JSON']>;
  filter?: Maybe<Scalars['JSON']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inbox?: Maybe<Ref>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  pinned?: Maybe<Scalars['Boolean']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export type Calendar = {
  __typename?: 'Calendar';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export type Campaign = {
  __typename?: 'Campaign';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  code?: Maybe<Scalars['ID']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export enum Color {
  Blue = 'blue',
  Blue_2 = 'blue_2',
  Blue_3 = 'blue_3',
  Blue_4 = 'blue_4',
  Blue_5 = 'blue_5',
  Brown = 'brown',
  Brown_2 = 'brown_2',
  Brown_3 = 'brown_3',
  Brown_4 = 'brown_4',
  Brown_5 = 'brown_5',
  Gray = 'gray',
  Gray_2 = 'gray_2',
  Gray_3 = 'gray_3',
  Gray_4 = 'gray_4',
  Gray_5 = 'gray_5',
  Green = 'green',
  Green_2 = 'green_2',
  Green_3 = 'green_3',
  Green_4 = 'green_4',
  Green_5 = 'green_5',
  Orange = 'orange',
  Orange_2 = 'orange_2',
  Orange_3 = 'orange_3',
  Orange_4 = 'orange_4',
  Orange_5 = 'orange_5',
  Pink = 'pink',
  Pink_2 = 'pink_2',
  Pink_3 = 'pink_3',
  Pink_4 = 'pink_4',
  Pink_5 = 'pink_5',
  Purple = 'purple',
  Purple_2 = 'purple_2',
  Purple_3 = 'purple_3',
  Purple_4 = 'purple_4',
  Purple_5 = 'purple_5',
  Red = 'red',
  Red_2 = 'red_2',
  Red_3 = 'red_3',
  Red_4 = 'red_4',
  Red_5 = 'red_5',
  Turquoise = 'turquoise',
  Turquoise_2 = 'turquoise_2',
  Turquoise_3 = 'turquoise_3',
  Turquoise_4 = 'turquoise_4',
  Turquoise_5 = 'turquoise_5',
  Yellow = 'yellow',
  Yellow_2 = 'yellow_2',
  Yellow_3 = 'yellow_3',
  Yellow_4 = 'yellow_4',
  Yellow_5 = 'yellow_5'
}

export type Company = {
  __typename?: 'Company';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  avatar?: Maybe<Scalars['String']>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  industry?: Maybe<Tag>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  type?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  websites?: Maybe<Scalars['JSON']>;
  workspaceId: Scalars['ID'];
};

export type Contact = {
  __typename?: 'Contact';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  avatar?: Maybe<Scalars['String']>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  type?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  websites?: Maybe<Scalars['JSON']>;
  workspaceId: Scalars['ID'];
};

export type Content = {
  __typename?: 'Content';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  code?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  publish?: Maybe<Scalars['DateTime']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export enum DayOfWeek {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export type Deal = {
  __typename?: 'Deal';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  value?: Maybe<Scalars['Float']>;
  workspaceId: Scalars['ID'];
};

export enum DisplayAs {
  Pane = 'pane',
  Property = 'property',
  Section = 'section'
}

export enum EntityType {
  Action = 'action',
  Agenda = 'agenda',
  Backlog = 'backlog',
  Calendar = 'calendar',
  Campaign = 'campaign',
  Company = 'company',
  Contact = 'contact',
  Content = 'content',
  Deal = 'deal',
  Event = 'event',
  Form = 'form',
  Knowledgebase = 'knowledgebase',
  Meeting = 'meeting',
  Note = 'note',
  Outcome = 'outcome',
  Page = 'page',
  Person = 'person',
  Pipeline = 'pipeline',
  Process = 'process',
  Project = 'project',
  Request = 'request',
  Resource = 'resource',
  Roadmap = 'roadmap',
  Schedule = 'schedule',
  Sprint = 'sprint',
  Task = 'task',
  Team = 'team',
  View = 'view',
  Workflow = 'workflow',
  WorkflowStep = 'workflow_step',
  Workspace = 'workspace'
}

export type Error = {
  __typename?: 'Error';
  code?: Maybe<ErrorCode>;
  handle?: Maybe<ErrorHandle>;
  message: Scalars['String'];
};

export enum ErrorCode {
  BadRequest = 'bad_request',
  Deleted = 'deleted',
  Forbidden = 'forbidden',
  InternalServerError = 'internal_server_error',
  NotFound = 'not_found',
  Unauthorized = 'unauthorized'
}

export enum ErrorHandle {
  Discard = 'discard',
  Fail = 'fail',
  Ignore = 'ignore',
  Retry = 'retry'
}

export type Event = {
  __typename?: 'Event';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum FailureHandle {
  Discard = 'discard',
  Fail = 'fail',
  Ignore = 'ignore',
  Retry = 'retry'
}

export type FetchInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  mode?: InputMaybe<FetchMode>;
  since?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  templates?: InputMaybe<Scalars['Boolean']>;
};

export enum FetchMode {
  All = 'all',
  Changed = 'changed',
  Ids = 'ids'
}

export type FetchResults = {
  __typename?: 'FetchResults';
  ids?: Maybe<Array<Scalars['ID']>>;
  items: Array<Scalars['JSON']>;
  more?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Fetchable = Action | Agenda | Backlog | Calendar | Campaign | Company | Contact | Content | Deal | Error | Event | Form | KnowledgeBase | Meeting | Note | Outcome | Page | Person | Pipeline | Process | Project | Request | Resource | Roadmap | Schedule | Sprint | Task | Team | View | Workflow | WorkflowStep | Workspace;

export type Filter = {
  __typename?: 'Filter';
  and?: Maybe<Array<Filter>>;
  field?: Maybe<Scalars['String']>;
  op?: Maybe<FilterOperation>;
  or?: Maybe<Array<Filter>>;
  type?: Maybe<PropertyType>;
  value?: Maybe<PropertyValue>;
  values?: Maybe<PropertyValues>;
};

export type FilterInput = {
  and?: InputMaybe<Array<FilterInput>>;
  empty?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<Scalars['String']>;
  op?: InputMaybe<FilterOperation>;
  or?: InputMaybe<Array<FilterInput>>;
  type?: InputMaybe<PropertyType>;
  value?: InputMaybe<PropertyValueInput>;
  values?: InputMaybe<PropertyValuesInput>;
};

export enum FilterOperation {
  After = 'after',
  Before = 'before',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsSome = 'contains_some',
  DoesNotContain = 'does_not_contain',
  DoesNotEqual = 'does_not_equal',
  EndsWith = 'ends_with',
  Equals = 'equals',
  GreaterThan = 'greater_than',
  IsEmpty = 'is_empty',
  IsNotEmpty = 'is_not_empty',
  LessThan = 'less_than',
  NextMonth = 'next_month',
  NextWeek = 'next_week',
  NextYear = 'next_year',
  OnOrAfter = 'on_or_after',
  OnOrBefore = 'on_or_before',
  PastMonth = 'past_month',
  PastWeek = 'past_week',
  PastYear = 'past_year',
  StartsWith = 'starts_with'
}

export type FilterScope = {
  entity: EntityType;
  scope?: InputMaybe<Scalars['String']>;
};

export type Form = {
  __typename?: 'Form';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  entity?: Maybe<EntityType>;
  fields?: Maybe<Scalars['JSON']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inLocation?: Maybe<Scalars['String']>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  overrides?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  purpose?: Maybe<Scalars['String']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  useTemplate?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum Hierarchy {
  Child = 'child',
  Parent = 'parent'
}

export enum Integration {
  Asana = 'asana',
  Figma = 'figma',
  Github = 'github',
  Google = 'google',
  Notion = 'notion',
  Slack = 'slack',
  Traction = 'traction',
  Zoom = 'zoom'
}

export type IntegrationIDs = {
  __typename?: 'IntegrationIDs';
  asana?: Maybe<Scalars['ID']>;
  figma?: Maybe<Scalars['ID']>;
  github?: Maybe<Scalars['ID']>;
  google?: Maybe<Scalars['ID']>;
  notion?: Maybe<Scalars['ID']>;
  slack?: Maybe<Scalars['ID']>;
  traction?: Maybe<Scalars['ID']>;
  zoom?: Maybe<Scalars['ID']>;
};

export type Job = {
  __typename?: 'Job';
  attempts?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Ref>;
  data?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  lockKey?: Maybe<Scalars['String']>;
  lockedAt?: Maybe<Scalars['DateTime']>;
  lockedBy?: Maybe<Ref>;
  status: JobStatus;
  updatedAt: Scalars['DateTime'];
};

export enum JobStatus {
  Completed = 'completed',
  Failed = 'failed',
  Queued = 'queued',
  Running = 'running'
}

export type KnowledgeBase = {
  __typename?: 'KnowledgeBase';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum Layout {
  Board = 'board',
  Browser = 'browser',
  Calendar = 'calendar',
  Canvas = 'canvas',
  Card = 'card',
  Columns = 'columns',
  Default = 'default',
  List = 'list',
  Table = 'table',
  Timeline = 'timeline'
}

export type Link = {
  __typename?: 'Link';
  icon?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  text?: Maybe<Scalars['String']>;
  url: Scalars['URL'];
};

export type LinkConfidence = {
  __typename?: 'LinkConfidence';
  confidence?: Maybe<Scalars['Float']>;
  link: Link;
};

export type LinkInput = {
  icon?: InputMaybe<Scalars['String']>;
  pinned?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['URL']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Ref>;
  purpose?: Maybe<Scalars['String']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _version?: Maybe<Scalars['String']>;
  authenticate: Session;
  authorize: Session;
  claimJob: Job;
  createPropertyDef: PropertyDef;
  createWorkspace: Session;
  deletePropertyDef: PropertyDef;
  join?: Maybe<WaitlistPosition>;
  process?: Maybe<ProcessResults>;
  updateJob: Job;
  updatePropertyDef: PropertyDef;
};


export type MutationAuthenticateArgs = {
  sso?: InputMaybe<SsoAuthInput>;
};


export type MutationAuthorizeArgs = {
  integration: SsoAuthInput;
};


export type MutationClaimJobArgs = {
  id: Scalars['ID'];
  lockKey: Scalars['ID'];
};


export type MutationCreatePropertyDefArgs = {
  changes?: InputMaybe<PropertyDefInput>;
  def: PropertyRefInput;
  scope: PropertyScope;
};


export type MutationCreateWorkspaceArgs = {
  icon?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationDeletePropertyDefArgs = {
  def: PropertyRefInput;
  scope: PropertyScope;
};


export type MutationJoinArgs = {
  data?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
};


export type MutationProcessArgs = {
  updates?: InputMaybe<Array<UpdateInput>>;
};


export type MutationUpdateJobArgs = {
  id: Scalars['ID'];
  status: JobStatus;
};


export type MutationUpdatePropertyDefArgs = {
  changes: PropertyDefInput;
  def: PropertyRefInput;
  scope: PropertyScope;
};

export type Note = {
  __typename?: 'Note';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  author?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  links?: Maybe<Array<Link>>;
  location: Scalars['ID'];
  pinned?: Maybe<Scalars['Boolean']>;
  refs?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  summary?: Maybe<RichText>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Outcome = {
  __typename?: 'Outcome';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  assigned?: Maybe<Ref>;
  blocked?: Maybe<Scalars['Boolean']>;
  body?: Maybe<RichText>;
  code?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  links?: Maybe<Array<Link>>;
  location: Scalars['ID'];
  notionId?: Maybe<Scalars['ID']>;
  orders?: Maybe<Scalars['JSON']>;
  refs?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  template?: Maybe<Template>;
  threads?: Maybe<Array<Link>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Page = {
  __typename?: 'Page';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum Period {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year'
}

export type Person = {
  __typename?: 'Person';
  aka?: Maybe<IntegrationIDs>;
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  email?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  refs?: Maybe<Scalars['JSON']>;
  role: PersonRole;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  teams?: Maybe<Array<Ref>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
};

export enum PersonRole {
  Admin = 'admin',
  Guest = 'guest',
  Member = 'member',
  Owner = 'owner'
}

export type Pipeline = {
  __typename?: 'Pipeline';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stageBy?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Process = {
  __typename?: 'Process';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type ProcessResults = {
  __typename?: 'ProcessResults';
  items: Array<Scalars['JSON']>;
};

export type Project = {
  __typename?: 'Project';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  links?: Maybe<Array<Link>>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notionId?: Maybe<Scalars['ID']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  pinned?: Maybe<Scalars['Boolean']>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  tags?: Maybe<Array<Tag>>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export type PropertyDef = {
  __typename?: 'PropertyDef';
  assist?: Maybe<AiAssist>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayAs?: Maybe<DisplayAs>;
  entity: Array<EntityType>;
  field: Scalars['String'];
  format?: Maybe<PropertyFormat>;
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Scalars['JSON']>;
  order?: Maybe<Scalars['Float']>;
  readonly?: Maybe<Scalars['Boolean']>;
  scope: Scalars['String'];
  type: PropertyType;
  updatedAt: Scalars['DateTime'];
  values?: Maybe<Array<PropertyValue>>;
  visibility?: Maybe<PropertyVisibility>;
};

export type PropertyDefInput = {
  addValues?: InputMaybe<Array<TagInput>>;
  assist?: InputMaybe<AiAssist>;
  displayAs?: InputMaybe<DisplayAs>;
  entity?: InputMaybe<Array<EntityType>>;
  format?: InputMaybe<PropertyFormat>;
  label?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Scalars['JSON']>;
  order?: InputMaybe<Scalars['Float']>;
  readonly?: InputMaybe<Scalars['Boolean']>;
  removeValues?: InputMaybe<Array<TagInput>>;
  type?: InputMaybe<PropertyType>;
  updateValues?: InputMaybe<Array<TagInput>>;
  values?: InputMaybe<Array<TagInput>>;
  visibility?: InputMaybe<PropertyVisibility>;
};

export enum PropertyFormat {
  Day = 'day',
  Days = 'days',
  Dollar = 'dollar',
  Float = 'float',
  Group = 'group',
  Hours = 'hours',
  Int = 'int',
  IntCommas = 'int_commas',
  Minutes = 'minutes',
  Month = 'month',
  Parent = 'parent',
  Percent = 'percent',
  Quarter = 'quarter',
  Root = 'root',
  Team = 'team',
  Time = 'time',
  TimeAgo = 'time_ago',
  Week = 'week',
  Year = 'year'
}

export type PropertyMutationInput = {
  field: Scalars['String'];
  op?: InputMaybe<PropertyMutationOperation>;
  type: PropertyType;
  value?: InputMaybe<PropertyValueInput>;
};

export enum PropertyMutationOperation {
  Add = 'add',
  Clear = 'clear',
  Remove = 'remove',
  Set = 'set'
}

export type PropertyRefInput = {
  field: Scalars['String'];
  type: PropertyType;
};

export type PropertyScope = {
  entity: EntityType;
  scope: Scalars['String'];
};

export enum PropertyType {
  Boolean = 'boolean',
  Checklist = 'checklist',
  Date = 'date',
  Formula = 'formula',
  Json = 'json',
  Link = 'link',
  Links = 'links',
  MultiSelect = 'multi_select',
  Number = 'number',
  Properties = 'properties',
  Property = 'property',
  Relation = 'relation',
  Relations = 'relations',
  RichText = 'rich_text',
  Select = 'select',
  Stamp = 'stamp',
  Status = 'status',
  Text = 'text'
}

export type PropertyValue = Ref | Tag;

export type PropertyValueInput = {
  boolean?: InputMaybe<Scalars['Boolean']>;
  checklist?: InputMaybe<RichTextInput>;
  date?: InputMaybe<Scalars['DateTime']>;
  formula?: InputMaybe<Scalars['String']>;
  json?: InputMaybe<Scalars['JSON']>;
  link?: InputMaybe<LinkInput>;
  links?: InputMaybe<Array<LinkInput>>;
  multi_select?: InputMaybe<Array<TagInput>>;
  number?: InputMaybe<Scalars['Float']>;
  properties?: InputMaybe<Scalars['JSON']>;
  property?: InputMaybe<Scalars['JSON']>;
  relation?: InputMaybe<RefInput>;
  relations?: InputMaybe<Array<RefInput>>;
  rich_text?: InputMaybe<RichTextInput>;
  select?: InputMaybe<TagInput>;
  stamp?: InputMaybe<StampInput>;
  status?: InputMaybe<TagInput>;
  text?: InputMaybe<Scalars['String']>;
};

export type PropertyValues = {
  __typename?: 'PropertyValues';
  boolean?: Maybe<Array<Scalars['Boolean']>>;
  checklist?: Maybe<Array<RichText>>;
  date?: Maybe<Array<Scalars['DateTime']>>;
  formula?: Maybe<Array<Scalars['String']>>;
  json?: Maybe<Array<Scalars['JSON']>>;
  link?: Maybe<Array<Link>>;
  links?: Maybe<Array<Link>>;
  multi_select?: Maybe<Array<Tag>>;
  number?: Maybe<Array<Scalars['Float']>>;
  properties?: Maybe<Array<Scalars['JSON']>>;
  property?: Maybe<Array<Scalars['JSON']>>;
  relation?: Maybe<Array<Ref>>;
  relations?: Maybe<Array<Ref>>;
  rich_text?: Maybe<Array<RichText>>;
  select?: Maybe<Array<Tag>>;
  stamps?: Maybe<Array<Stamp>>;
  status?: Maybe<Array<Tag>>;
  text?: Maybe<Array<Scalars['String']>>;
};

export type PropertyValuesInput = {
  links?: InputMaybe<Array<LinkInput>>;
  multi_select?: InputMaybe<Array<TagInput>>;
  relation?: InputMaybe<Array<RefInput>>;
  relations?: InputMaybe<Array<RefInput>>;
  select?: InputMaybe<Array<TagInput>>;
  status?: InputMaybe<Array<TagInput>>;
};

export enum PropertyVisibility {
  Hidden = 'hidden',
  HideAlways = 'hide_always',
  HideEmpty = 'hide_empty',
  ShowAlways = 'show_always'
}

export type Query = {
  __typename?: 'Query';
  _version?: Maybe<Scalars['String']>;
  fetch?: Maybe<FetchResults>;
  filter?: Maybe<FetchResults>;
  jobQueue: Array<Job>;
  linkMeta: LinkConfidence;
  propertyDef: PropertyDef;
  propertyDefs: Array<PropertyDef>;
  search?: Maybe<FetchResults>;
  session: Session;
  switchableSessions: Array<Session>;
};


export type QueryFetchArgs = {
  ids: Array<Scalars['ID']>;
  opts?: InputMaybe<FetchInput>;
};


export type QueryFilterArgs = {
  filter?: InputMaybe<FilterInput>;
  filterUnsafe?: InputMaybe<Scalars['JSON']>;
  opts?: InputMaybe<FetchInput>;
  scope: FilterScope;
};


export type QueryLinkMetaArgs = {
  url: Scalars['URL'];
};


export type QueryPropertyDefArgs = {
  prop: PropertyRefInput;
  scope: PropertyScope;
};


export type QueryPropertyDefsArgs = {
  opts?: InputMaybe<FetchInput>;
  scope: PropertyScope;
};


export type QuerySearchArgs = {
  opts?: InputMaybe<SearchInput>;
  query?: InputMaybe<Scalars['String']>;
  type: EntityType;
};

export type Ref = {
  __typename?: 'Ref';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type RefInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RelationOptions = {
  __typename?: 'RelationOptions';
  hierarchy?: Maybe<Hierarchy>;
  references?: Maybe<EntityType>;
  sync?: Maybe<EntityType>;
};

export type Request = {
  __typename?: 'Request';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  assigned?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Resource = {
  __typename?: 'Resource';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pinned?: Maybe<Scalars['Boolean']>;
  refs?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  url?: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export enum ResourceType {
  File = 'file',
  Link = 'link'
}

export type RichText = {
  __typename?: 'RichText';
  html?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  text?: Maybe<Scalars['String']>;
};

export type RichTextInput = {
  html?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['JSON']>;
  text?: InputMaybe<Scalars['String']>;
};

export type Roadmap = {
  __typename?: 'Roadmap';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  body?: Maybe<RichText>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  fields?: Maybe<Scalars['JSON']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  period: Period;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export type SsoAuthInput = {
  scope: AuthScope;
  source: Integration;
  token: Scalars['String'];
};

export type Schedule = {
  __typename?: 'Schedule';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  daysOfPeriod?: Maybe<Array<Scalars['Int']>>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  entity?: Maybe<EntityType>;
  field?: Maybe<Scalars['String']>;
  frequency: Scalars['Int'];
  from: Scalars['DateTime'];
  id: Scalars['ID'];
  instances?: Maybe<Array<Ref>>;
  last?: Maybe<Scalars['DateTime']>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  next?: Maybe<Scalars['DateTime']>;
  overrides?: Maybe<Scalars['JSON']>;
  period: Period;
  precreate: Scalars['Int'];
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  timeOfDay?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  useTemplate?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type SearchInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<SearchMode>;
  mode?: InputMaybe<FetchMode>;
  since?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  templates?: InputMaybe<Scalars['Boolean']>;
};

export enum SearchMode {
  Contains = 'contains',
  Fuzzy = 'fuzzy'
}

export type Session = {
  __typename?: 'Session';
  auths: Scalars['JSON'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  person: Person;
  token: Scalars['String'];
  workspace?: Maybe<Workspace>;
};

export type Sprint = {
  __typename?: 'Sprint';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  code?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  duration?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  people?: Maybe<Array<Ref>>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  workspaceId: Scalars['ID'];
};

export type Stamp = {
  __typename?: 'Stamp';
  at?: Maybe<Scalars['Int']>;
  by?: Maybe<Scalars['ID']>;
};

export type StampInput = {
  at?: InputMaybe<Scalars['Int']>;
  by?: InputMaybe<Scalars['ID']>;
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TagInput = {
  color?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  assigned?: Maybe<Ref>;
  blocked?: Maybe<Scalars['Boolean']>;
  body?: Maybe<RichText>;
  checklist?: Maybe<RichText>;
  code?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  links?: Maybe<Array<Link>>;
  location: Scalars['ID'];
  notionId?: Maybe<Scalars['ID']>;
  orders?: Maybe<Scalars['JSON']>;
  refs?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Tag>;
  summary?: Maybe<RichText>;
  tags?: Maybe<Array<Tag>>;
  template?: Maybe<Template>;
  threads?: Maybe<Array<Link>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Team = {
  __typename?: 'Team';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notionId?: Maybe<Scalars['ID']>;
  owner?: Maybe<Ref>;
  parent?: Maybe<Ref>;
  people?: Maybe<Array<Ref>>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  subTeams?: Maybe<Array<Ref>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  views?: Maybe<Array<Ref>>;
  visibility: TeamVisibility;
  workspaceId: Scalars['ID'];
};

export enum TeamVisibility {
  Closed = 'closed',
  Open = 'open',
  Private = 'private'
}

export enum Template {
  Nested = 'nested',
  Root = 'root'
}

export type UpdateInput = {
  changes?: InputMaybe<Array<PropertyMutationInput>>;
  id: Scalars['String'];
  method: Scalars['String'];
  scope?: InputMaybe<Scalars['String']>;
  transaction?: InputMaybe<Scalars['String']>;
  type: EntityType;
};

export type View = {
  __typename?: 'View';
  aggregate?: Maybe<Scalars['JSON']>;
  alias?: Maybe<Scalars['ID']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  entity?: Maybe<EntityType>;
  filter?: Maybe<Scalars['JSON']>;
  for?: Maybe<Ref>;
  group?: Maybe<Scalars['JSON']>;
  grouping?: Maybe<ViewGrouping>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  layout?: Maybe<Layout>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  showProps?: Maybe<Scalars['JSON']>;
  sort?: Maybe<Scalars['JSON']>;
  team?: Maybe<Ref>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export enum ViewGrouping {
  Columns = 'columns',
  ColumnsRows = 'columns_rows',
  None = 'none',
  Quadrants = 'quadrants',
  Rows = 'rows'
}

export type WaitlistPosition = {
  __typename?: 'WaitlistPosition';
  data?: Maybe<Scalars['JSON']>;
  email: Scalars['String'];
  position: Scalars['Int'];
};

export type Workflow = {
  __typename?: 'Workflow';
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  inputs?: Maybe<Scalars['JSON']>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  vars?: Maybe<Scalars['JSON']>;
  workspaceId: Scalars['ID'];
};

export enum WorkflowAction {
  Ai = 'ai',
  Api = 'api',
  Condition = 'condition',
  Control = 'control',
  Create = 'create',
  Entry = 'entry',
  Exit = 'exit',
  Find = 'find',
  Message = 'message',
  RunWorkflow = 'run_workflow',
  SetVar = 'set_var',
  Update = 'update',
  Wait = 'wait'
}

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  action?: Maybe<WorkflowAction>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  archivedBy?: Maybe<Ref>;
  condition?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Ref>;
  custom?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Ref>;
  id: Scalars['ID'];
  inputs?: Maybe<Scalars['JSON']>;
  location: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  orders?: Maybe<Scalars['JSON']>;
  outputs?: Maybe<Scalars['JSON']>;
  overrides?: Maybe<Scalars['JSON']>;
  owner?: Maybe<Ref>;
  refs?: Maybe<Scalars['JSON']>;
  settings?: Maybe<Scalars['JSON']>;
  stamps?: Maybe<Scalars['JSON']>;
  status?: Maybe<Tag>;
  template?: Maybe<Template>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Ref>;
  workspaceId: Scalars['ID'];
};

export type Workspace = {
  __typename?: 'Workspace';
  aka?: Maybe<IntegrationIDs>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  setupAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Action: ResolverTypeWrapper<Action>;
  Agenda: ResolverTypeWrapper<Agenda>;
  AiAssist: AiAssist;
  Auth: ResolverTypeWrapper<Auth>;
  AuthDetails: ResolverTypeWrapper<AuthDetails>;
  AuthScope: AuthScope;
  Auths: ResolverTypeWrapper<Auths>;
  Backlog: ResolverTypeWrapper<Backlog>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Calendar: ResolverTypeWrapper<Calendar>;
  Campaign: ResolverTypeWrapper<Campaign>;
  Color: Color;
  Company: ResolverTypeWrapper<Company>;
  Contact: ResolverTypeWrapper<Contact>;
  Content: ResolverTypeWrapper<Content>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DayOfWeek: DayOfWeek;
  Deal: ResolverTypeWrapper<Deal>;
  DisplayAs: DisplayAs;
  EntityType: EntityType;
  Error: ResolverTypeWrapper<Error>;
  ErrorCode: ErrorCode;
  ErrorHandle: ErrorHandle;
  Event: ResolverTypeWrapper<Event>;
  FailureHandle: FailureHandle;
  FetchInput: FetchInput;
  FetchMode: FetchMode;
  FetchResults: ResolverTypeWrapper<FetchResults>;
  Fetchable: ResolversTypes['Action'] | ResolversTypes['Agenda'] | ResolversTypes['Backlog'] | ResolversTypes['Calendar'] | ResolversTypes['Campaign'] | ResolversTypes['Company'] | ResolversTypes['Contact'] | ResolversTypes['Content'] | ResolversTypes['Deal'] | ResolversTypes['Error'] | ResolversTypes['Event'] | ResolversTypes['Form'] | ResolversTypes['KnowledgeBase'] | ResolversTypes['Meeting'] | ResolversTypes['Note'] | ResolversTypes['Outcome'] | ResolversTypes['Page'] | ResolversTypes['Person'] | ResolversTypes['Pipeline'] | ResolversTypes['Process'] | ResolversTypes['Project'] | ResolversTypes['Request'] | ResolversTypes['Resource'] | ResolversTypes['Roadmap'] | ResolversTypes['Schedule'] | ResolversTypes['Sprint'] | ResolversTypes['Task'] | ResolversTypes['Team'] | ResolversTypes['View'] | ResolversTypes['Workflow'] | ResolversTypes['WorkflowStep'] | ResolversTypes['Workspace'];
  Filter: ResolverTypeWrapper<Omit<Filter, 'value'> & { value?: Maybe<ResolversTypes['PropertyValue']> }>;
  FilterInput: FilterInput;
  FilterOperation: FilterOperation;
  FilterScope: FilterScope;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  Form: ResolverTypeWrapper<Form>;
  HexCode: ResolverTypeWrapper<Scalars['HexCode']>;
  Hierarchy: Hierarchy;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Integration: Integration;
  IntegrationIDs: ResolverTypeWrapper<IntegrationIDs>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  Job: ResolverTypeWrapper<Job>;
  JobStatus: JobStatus;
  KnowledgeBase: ResolverTypeWrapper<KnowledgeBase>;
  Layout: Layout;
  Link: ResolverTypeWrapper<Link>;
  LinkConfidence: ResolverTypeWrapper<LinkConfidence>;
  LinkInput: LinkInput;
  Meeting: ResolverTypeWrapper<Meeting>;
  Mutation: ResolverTypeWrapper<{}>;
  Note: ResolverTypeWrapper<Note>;
  Outcome: ResolverTypeWrapper<Outcome>;
  Page: ResolverTypeWrapper<Page>;
  Period: Period;
  Person: ResolverTypeWrapper<Person>;
  PersonRole: PersonRole;
  Pipeline: ResolverTypeWrapper<Pipeline>;
  Process: ResolverTypeWrapper<Process>;
  ProcessResults: ResolverTypeWrapper<ProcessResults>;
  Project: ResolverTypeWrapper<Project>;
  PropertyDef: ResolverTypeWrapper<Omit<PropertyDef, 'values'> & { values?: Maybe<Array<ResolversTypes['PropertyValue']>> }>;
  PropertyDefInput: PropertyDefInput;
  PropertyFormat: PropertyFormat;
  PropertyMutationInput: PropertyMutationInput;
  PropertyMutationOperation: PropertyMutationOperation;
  PropertyRefInput: PropertyRefInput;
  PropertyScope: PropertyScope;
  PropertyType: PropertyType;
  PropertyValue: ResolversTypes['Ref'] | ResolversTypes['Tag'];
  PropertyValueInput: PropertyValueInput;
  PropertyValues: ResolverTypeWrapper<PropertyValues>;
  PropertyValuesInput: PropertyValuesInput;
  PropertyVisibility: PropertyVisibility;
  Query: ResolverTypeWrapper<{}>;
  Ref: ResolverTypeWrapper<Ref>;
  RefInput: RefInput;
  RelationOptions: ResolverTypeWrapper<RelationOptions>;
  Request: ResolverTypeWrapper<Request>;
  Resource: ResolverTypeWrapper<Resource>;
  ResourceType: ResourceType;
  RichText: ResolverTypeWrapper<RichText>;
  RichTextInput: RichTextInput;
  Roadmap: ResolverTypeWrapper<Roadmap>;
  SSOAuthInput: SsoAuthInput;
  Schedule: ResolverTypeWrapper<Schedule>;
  SearchInput: SearchInput;
  SearchMode: SearchMode;
  Session: ResolverTypeWrapper<Session>;
  Sprint: ResolverTypeWrapper<Sprint>;
  Stamp: ResolverTypeWrapper<Stamp>;
  StampInput: StampInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  Tag: ResolverTypeWrapper<Tag>;
  TagInput: TagInput;
  Task: ResolverTypeWrapper<Task>;
  Team: ResolverTypeWrapper<Team>;
  TeamVisibility: TeamVisibility;
  Template: Template;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  URL: ResolverTypeWrapper<Scalars['URL']>;
  UpdateInput: UpdateInput;
  View: ResolverTypeWrapper<View>;
  ViewGrouping: ViewGrouping;
  WaitlistPosition: ResolverTypeWrapper<WaitlistPosition>;
  Workflow: ResolverTypeWrapper<Workflow>;
  WorkflowAction: WorkflowAction;
  WorkflowStep: ResolverTypeWrapper<WorkflowStep>;
  Workspace: ResolverTypeWrapper<Workspace>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Action: Action;
  Agenda: Agenda;
  Auth: Auth;
  AuthDetails: AuthDetails;
  Auths: Auths;
  Backlog: Backlog;
  Boolean: Scalars['Boolean'];
  Calendar: Calendar;
  Campaign: Campaign;
  Company: Company;
  Contact: Contact;
  Content: Content;
  DateTime: Scalars['DateTime'];
  Deal: Deal;
  Error: Error;
  Event: Event;
  FetchInput: FetchInput;
  FetchResults: FetchResults;
  Fetchable: ResolversParentTypes['Action'] | ResolversParentTypes['Agenda'] | ResolversParentTypes['Backlog'] | ResolversParentTypes['Calendar'] | ResolversParentTypes['Campaign'] | ResolversParentTypes['Company'] | ResolversParentTypes['Contact'] | ResolversParentTypes['Content'] | ResolversParentTypes['Deal'] | ResolversParentTypes['Error'] | ResolversParentTypes['Event'] | ResolversParentTypes['Form'] | ResolversParentTypes['KnowledgeBase'] | ResolversParentTypes['Meeting'] | ResolversParentTypes['Note'] | ResolversParentTypes['Outcome'] | ResolversParentTypes['Page'] | ResolversParentTypes['Person'] | ResolversParentTypes['Pipeline'] | ResolversParentTypes['Process'] | ResolversParentTypes['Project'] | ResolversParentTypes['Request'] | ResolversParentTypes['Resource'] | ResolversParentTypes['Roadmap'] | ResolversParentTypes['Schedule'] | ResolversParentTypes['Sprint'] | ResolversParentTypes['Task'] | ResolversParentTypes['Team'] | ResolversParentTypes['View'] | ResolversParentTypes['Workflow'] | ResolversParentTypes['WorkflowStep'] | ResolversParentTypes['Workspace'];
  Filter: Omit<Filter, 'value'> & { value?: Maybe<ResolversParentTypes['PropertyValue']> };
  FilterInput: FilterInput;
  FilterScope: FilterScope;
  Float: Scalars['Float'];
  Form: Form;
  HexCode: Scalars['HexCode'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  IntegrationIDs: IntegrationIDs;
  JSON: Scalars['JSON'];
  Job: Job;
  KnowledgeBase: KnowledgeBase;
  Link: Link;
  LinkConfidence: LinkConfidence;
  LinkInput: LinkInput;
  Meeting: Meeting;
  Mutation: {};
  Note: Note;
  Outcome: Outcome;
  Page: Page;
  Person: Person;
  Pipeline: Pipeline;
  Process: Process;
  ProcessResults: ProcessResults;
  Project: Project;
  PropertyDef: Omit<PropertyDef, 'values'> & { values?: Maybe<Array<ResolversParentTypes['PropertyValue']>> };
  PropertyDefInput: PropertyDefInput;
  PropertyMutationInput: PropertyMutationInput;
  PropertyRefInput: PropertyRefInput;
  PropertyScope: PropertyScope;
  PropertyValue: ResolversParentTypes['Ref'] | ResolversParentTypes['Tag'];
  PropertyValueInput: PropertyValueInput;
  PropertyValues: PropertyValues;
  PropertyValuesInput: PropertyValuesInput;
  Query: {};
  Ref: Ref;
  RefInput: RefInput;
  RelationOptions: RelationOptions;
  Request: Request;
  Resource: Resource;
  RichText: RichText;
  RichTextInput: RichTextInput;
  Roadmap: Roadmap;
  SSOAuthInput: SsoAuthInput;
  Schedule: Schedule;
  SearchInput: SearchInput;
  Session: Session;
  Sprint: Sprint;
  Stamp: Stamp;
  StampInput: StampInput;
  String: Scalars['String'];
  Tag: Tag;
  TagInput: TagInput;
  Task: Task;
  Team: Team;
  Time: Scalars['Time'];
  URL: Scalars['URL'];
  UpdateInput: UpdateInput;
  View: View;
  WaitlistPosition: WaitlistPosition;
  Workflow: Workflow;
  WorkflowStep: WorkflowStep;
  Workspace: Workspace;
}>;

export type ActionResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Action'] = ResolversParentTypes['Action']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  open?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgendaResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Agenda'] = ResolversParentTypes['Agenda']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  open?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Auth'] = ResolversParentTypes['Auth']> = ResolversObject<{
  bot?: Resolver<Maybe<ResolversTypes['AuthDetails']>, ParentType, ContextType>;
  source?: Resolver<ResolversTypes['Integration'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['AuthDetails']>, ParentType, ContextType>;
  workspace?: Resolver<Maybe<ResolversTypes['AuthDetails']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthDetailsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['AuthDetails'] = ResolversParentTypes['AuthDetails']> = ResolversObject<{
  authedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Auths'] = ResolversParentTypes['Auths']> = ResolversObject<{
  asana?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  figma?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  google?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  notion?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  slack?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  traction?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['Auth']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BacklogResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Backlog'] = ResolversParentTypes['Backlog']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inbox?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CalendarResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Calendar'] = ResolversParentTypes['Calendar']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CampaignResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Campaign'] = ResolversParentTypes['Campaign']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CompanyResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  websites?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  websites?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContentResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Content'] = ResolversParentTypes['Content']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  publish?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DealResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Deal'] = ResolversParentTypes['Deal']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ErrorResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']> = ResolversObject<{
  code?: Resolver<Maybe<ResolversTypes['ErrorCode']>, ParentType, ContextType>;
  handle?: Resolver<Maybe<ResolversTypes['ErrorHandle']>, ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchResultsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['FetchResults'] = ResolversParentTypes['FetchResults']> = ResolversObject<{
  ids?: Resolver<Maybe<Array<ResolversTypes['ID']>>, ParentType, ContextType>;
  items?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType>;
  more?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  skip?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  take?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FetchableResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Fetchable'] = ResolversParentTypes['Fetchable']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Action' | 'Agenda' | 'Backlog' | 'Calendar' | 'Campaign' | 'Company' | 'Contact' | 'Content' | 'Deal' | 'Error' | 'Event' | 'Form' | 'KnowledgeBase' | 'Meeting' | 'Note' | 'Outcome' | 'Page' | 'Person' | 'Pipeline' | 'Process' | 'Project' | 'Request' | 'Resource' | 'Roadmap' | 'Schedule' | 'Sprint' | 'Task' | 'Team' | 'View' | 'Workflow' | 'WorkflowStep' | 'Workspace', ParentType, ContextType>;
}>;

export type FilterResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Filter'] = ResolversParentTypes['Filter']> = ResolversObject<{
  and?: Resolver<Maybe<Array<ResolversTypes['Filter']>>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  op?: Resolver<Maybe<ResolversTypes['FilterOperation']>, ParentType, ContextType>;
  or?: Resolver<Maybe<Array<ResolversTypes['Filter']>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['PropertyType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['PropertyValue']>, ParentType, ContextType>;
  values?: Resolver<Maybe<ResolversTypes['PropertyValues']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type FormResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Form'] = ResolversParentTypes['Form']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  entity?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  overrides?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  purpose?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  useTemplate?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface HexCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['HexCode'], any> {
  name: 'HexCode';
}

export type IntegrationIDsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['IntegrationIDs'] = ResolversParentTypes['IntegrationIDs']> = ResolversObject<{
  asana?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  figma?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  google?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  notion?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  slack?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  traction?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type JobResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']> = ResolversObject<{
  attempts?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lockKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lockedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lockedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['JobStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type KnowledgeBaseResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['KnowledgeBase'] = ResolversParentTypes['KnowledgeBase']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LinkResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Link'] = ResolversParentTypes['Link']> = ResolversObject<{
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LinkConfidenceResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['LinkConfidence'] = ResolversParentTypes['LinkConfidence']> = ResolversObject<{
  confidence?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  link?: Resolver<ResolversTypes['Link'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MeetingResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Meeting'] = ResolversParentTypes['Meeting']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  purpose?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  _version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  authenticate?: Resolver<ResolversTypes['Session'], ParentType, ContextType, Partial<MutationAuthenticateArgs>>;
  authorize?: Resolver<ResolversTypes['Session'], ParentType, ContextType, RequireFields<MutationAuthorizeArgs, 'integration'>>;
  claimJob?: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<MutationClaimJobArgs, 'id' | 'lockKey'>>;
  createPropertyDef?: Resolver<ResolversTypes['PropertyDef'], ParentType, ContextType, RequireFields<MutationCreatePropertyDefArgs, 'def' | 'scope'>>;
  createWorkspace?: Resolver<ResolversTypes['Session'], ParentType, ContextType, RequireFields<MutationCreateWorkspaceArgs, 'name'>>;
  deletePropertyDef?: Resolver<ResolversTypes['PropertyDef'], ParentType, ContextType, RequireFields<MutationDeletePropertyDefArgs, 'def' | 'scope'>>;
  join?: Resolver<Maybe<ResolversTypes['WaitlistPosition']>, ParentType, ContextType, Partial<MutationJoinArgs>>;
  process?: Resolver<Maybe<ResolversTypes['ProcessResults']>, ParentType, ContextType, Partial<MutationProcessArgs>>;
  updateJob?: Resolver<ResolversTypes['Job'], ParentType, ContextType, RequireFields<MutationUpdateJobArgs, 'id' | 'status'>>;
  updatePropertyDef?: Resolver<ResolversTypes['PropertyDef'], ParentType, ContextType, RequireFields<MutationUpdatePropertyDefArgs, 'changes' | 'def' | 'scope'>>;
}>;

export type NoteResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Note'] = ResolversParentTypes['Note']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  author?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OutcomeResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Outcome'] = ResolversParentTypes['Outcome']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  threads?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PersonResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Person'] = ResolversParentTypes['Person']> = ResolversObject<{
  aka?: Resolver<Maybe<ResolversTypes['IntegrationIDs']>, ParentType, ContextType>;
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['PersonRole'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  teams?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PipelineResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Pipeline'] = ResolversParentTypes['Pipeline']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stageBy?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcessResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Process'] = ResolversParentTypes['Process']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProcessResultsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['ProcessResults'] = ResolversParentTypes['ProcessResults']> = ResolversObject<{
  items?: Resolver<Array<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ProjectResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropertyDefResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['PropertyDef'] = ResolversParentTypes['PropertyDef']> = ResolversObject<{
  assist?: Resolver<Maybe<ResolversTypes['AiAssist']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  displayAs?: Resolver<Maybe<ResolversTypes['DisplayAs']>, ParentType, ContextType>;
  entity?: Resolver<Array<ResolversTypes['EntityType']>, ParentType, ContextType>;
  field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['PropertyFormat']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  readonly?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  values?: Resolver<Maybe<Array<ResolversTypes['PropertyValue']>>, ParentType, ContextType>;
  visibility?: Resolver<Maybe<ResolversTypes['PropertyVisibility']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PropertyValueResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['PropertyValue'] = ResolversParentTypes['PropertyValue']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Ref' | 'Tag', ParentType, ContextType>;
}>;

export type PropertyValuesResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['PropertyValues'] = ResolversParentTypes['PropertyValues']> = ResolversObject<{
  boolean?: Resolver<Maybe<Array<ResolversTypes['Boolean']>>, ParentType, ContextType>;
  checklist?: Resolver<Maybe<Array<ResolversTypes['RichText']>>, ParentType, ContextType>;
  date?: Resolver<Maybe<Array<ResolversTypes['DateTime']>>, ParentType, ContextType>;
  formula?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  json?: Resolver<Maybe<Array<ResolversTypes['JSON']>>, ParentType, ContextType>;
  link?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  multi_select?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  number?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  properties?: Resolver<Maybe<Array<ResolversTypes['JSON']>>, ParentType, ContextType>;
  property?: Resolver<Maybe<Array<ResolversTypes['JSON']>>, ParentType, ContextType>;
  relation?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  relations?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  rich_text?: Resolver<Maybe<Array<ResolversTypes['RichText']>>, ParentType, ContextType>;
  select?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<Array<ResolversTypes['Stamp']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  text?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  _version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fetch?: Resolver<Maybe<ResolversTypes['FetchResults']>, ParentType, ContextType, RequireFields<QueryFetchArgs, 'ids'>>;
  filter?: Resolver<Maybe<ResolversTypes['FetchResults']>, ParentType, ContextType, RequireFields<QueryFilterArgs, 'scope'>>;
  jobQueue?: Resolver<Array<ResolversTypes['Job']>, ParentType, ContextType>;
  linkMeta?: Resolver<ResolversTypes['LinkConfidence'], ParentType, ContextType, RequireFields<QueryLinkMetaArgs, 'url'>>;
  propertyDef?: Resolver<ResolversTypes['PropertyDef'], ParentType, ContextType, RequireFields<QueryPropertyDefArgs, 'prop' | 'scope'>>;
  propertyDefs?: Resolver<Array<ResolversTypes['PropertyDef']>, ParentType, ContextType, RequireFields<QueryPropertyDefsArgs, 'scope'>>;
  search?: Resolver<Maybe<ResolversTypes['FetchResults']>, ParentType, ContextType, RequireFields<QuerySearchArgs, 'type'>>;
  session?: Resolver<ResolversTypes['Session'], ParentType, ContextType>;
  switchableSessions?: Resolver<Array<ResolversTypes['Session']>, ParentType, ContextType>;
}>;

export type RefResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Ref'] = ResolversParentTypes['Ref']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RelationOptionsResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['RelationOptions'] = ResolversParentTypes['RelationOptions']> = ResolversObject<{
  hierarchy?: Resolver<Maybe<ResolversTypes['Hierarchy']>, ParentType, ContextType>;
  references?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  sync?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RequestResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Request'] = ResolversParentTypes['Request']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ResourceResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Resource'] = ResolversParentTypes['Resource']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pinned?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RichTextResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['RichText'] = ResolversParentTypes['RichText']> = ResolversObject<{
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  markdown?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoadmapResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Roadmap'] = ResolversParentTypes['Roadmap']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  period?: Resolver<ResolversTypes['Period'], ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ScheduleResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Schedule'] = ResolversParentTypes['Schedule']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  daysOfPeriod?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  entity?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  frequency?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instances?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  last?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  overrides?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  period?: Resolver<ResolversTypes['Period'], ParentType, ContextType>;
  precreate?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  timeOfDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  useTemplate?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SessionResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Session'] = ResolversParentTypes['Session']> = ResolversObject<{
  auths?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  person?: Resolver<ResolversTypes['Person'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  workspace?: Resolver<Maybe<ResolversTypes['Workspace']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SprintResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Sprint'] = ResolversParentTypes['Sprint']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  people?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StampResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Stamp'] = ResolversParentTypes['Stamp']> = ResolversObject<{
  at?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  by?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TagResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag']> = ResolversObject<{
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TaskResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Task'] = ResolversParentTypes['Task']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  assigned?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  blocked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  checklist?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  links?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['RichText']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  threads?: Resolver<Maybe<Array<ResolversTypes['Link']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TeamResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Team'] = ResolversParentTypes['Team']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notionId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  people?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  subTeams?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  views?: Resolver<Maybe<Array<ResolversTypes['Ref']>>, ParentType, ContextType>;
  visibility?: Resolver<ResolversTypes['TeamVisibility'], ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export type ViewResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['View'] = ResolversParentTypes['View']> = ResolversObject<{
  aggregate?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  alias?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  entity?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  for?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  grouping?: Resolver<Maybe<ResolversTypes['ViewGrouping']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  layout?: Resolver<Maybe<ResolversTypes['Layout']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  showProps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  team?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WaitlistPositionResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['WaitlistPosition'] = ResolversParentTypes['WaitlistPosition']> = ResolversObject<{
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkflowResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Workflow'] = ResolversParentTypes['Workflow']> = ResolversObject<{
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inputs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  vars?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkflowStepResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['WorkflowStep'] = ResolversParentTypes['WorkflowStep']> = ResolversObject<{
  action?: Resolver<Maybe<ResolversTypes['WorkflowAction']>, ParentType, ContextType>;
  archivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  archivedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  condition?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  custom?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inputs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  orders?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  outputs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  overrides?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  refs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  stamps?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['Tag']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['Ref']>, ParentType, ContextType>;
  workspaceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WorkspaceResolvers<ContextType = RequestContext, ParentType extends ResolversParentTypes['Workspace'] = ResolversParentTypes['Workspace']> = ResolversObject<{
  aka?: Resolver<Maybe<ResolversTypes['IntegrationIDs']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  setupAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = RequestContext> = ResolversObject<{
  Action?: ActionResolvers<ContextType>;
  Agenda?: AgendaResolvers<ContextType>;
  Auth?: AuthResolvers<ContextType>;
  AuthDetails?: AuthDetailsResolvers<ContextType>;
  Auths?: AuthsResolvers<ContextType>;
  Backlog?: BacklogResolvers<ContextType>;
  Calendar?: CalendarResolvers<ContextType>;
  Campaign?: CampaignResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  Content?: ContentResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Deal?: DealResolvers<ContextType>;
  Error?: ErrorResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  FetchResults?: FetchResultsResolvers<ContextType>;
  Fetchable?: FetchableResolvers<ContextType>;
  Filter?: FilterResolvers<ContextType>;
  Form?: FormResolvers<ContextType>;
  HexCode?: GraphQLScalarType;
  IntegrationIDs?: IntegrationIDsResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Job?: JobResolvers<ContextType>;
  KnowledgeBase?: KnowledgeBaseResolvers<ContextType>;
  Link?: LinkResolvers<ContextType>;
  LinkConfidence?: LinkConfidenceResolvers<ContextType>;
  Meeting?: MeetingResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Note?: NoteResolvers<ContextType>;
  Outcome?: OutcomeResolvers<ContextType>;
  Page?: PageResolvers<ContextType>;
  Person?: PersonResolvers<ContextType>;
  Pipeline?: PipelineResolvers<ContextType>;
  Process?: ProcessResolvers<ContextType>;
  ProcessResults?: ProcessResultsResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  PropertyDef?: PropertyDefResolvers<ContextType>;
  PropertyValue?: PropertyValueResolvers<ContextType>;
  PropertyValues?: PropertyValuesResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Ref?: RefResolvers<ContextType>;
  RelationOptions?: RelationOptionsResolvers<ContextType>;
  Request?: RequestResolvers<ContextType>;
  Resource?: ResourceResolvers<ContextType>;
  RichText?: RichTextResolvers<ContextType>;
  Roadmap?: RoadmapResolvers<ContextType>;
  Schedule?: ScheduleResolvers<ContextType>;
  Session?: SessionResolvers<ContextType>;
  Sprint?: SprintResolvers<ContextType>;
  Stamp?: StampResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  Task?: TaskResolvers<ContextType>;
  Team?: TeamResolvers<ContextType>;
  Time?: GraphQLScalarType;
  URL?: GraphQLScalarType;
  View?: ViewResolvers<ContextType>;
  WaitlistPosition?: WaitlistPositionResolvers<ContextType>;
  Workflow?: WorkflowResolvers<ContextType>;
  WorkflowStep?: WorkflowStepResolvers<ContextType>;
  Workspace?: WorkspaceResolvers<ContextType>;
}>;

