import { first, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Campaign, canCreate, EntityType, ID, Ref, View } from "@api";

import { useLocalChanges } from "@state/generic";
import { useQuickSearch } from "@state/quick-filters";
import { useEntityLabels } from "@state/settings";
import {
  isFiltering,
  useLazyGetView,
  useLazyItemsForView,
  useLazyViewsForParent,
  useReorderItemsInView,
  useSmartProps,
  ViewStoreAtom,
} from "@state/views";

import { justOne, OneOrMany, whenEmpty } from "@utils/array";
import { Fn } from "@utils/fn";
import { GroupedItems, toViewingWithinScope } from "@utils/grouping";
import { Maybe, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import {
  isAnyRelation,
  isEmptyRef,
  toKey,
  toLabel,
} from "@utils/property-refs";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { Dropdown } from "@ui/dropdown";
import { render, useEngine } from "@ui/engine";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowUpRight, FilterAlt, PlusIcon, ViewIcon } from "@ui/icon";
import { SectionLabel, Text, TextXLarge } from "@ui/text";
import { ViewChangesActions } from "@ui/view-changes-actions";
import { FilterOptionsMenu, LayoutOptionsMenu } from "@ui/view-options-menu";
import { Props as ViewResultsListProps } from "@ui/view-results-list";
import { WithViewingWithin } from "@ui/viewing-within";

import { AddWorkActionMenu } from "./add-work-dialog";
import { RelationText } from "./relation-label";
import { ViewQuickSearch } from "./view-header";
import { BrowserLayout } from "./view-layouts";

import styles from "./view-results-page.module.css";

type PageProps = ViewResultsListProps & {
  showOtherViews?: boolean;
  sectionLabel?: string;
};
type EntityViewResultsProps = Omit<ViewResultsListProps, "viewId"> & {
  parentId: ID;
  childType: EntityType;
};

export const ViewResultsPage = ({
  viewId,
  sectionLabel,
  showOtherViews = false,
  ...rest
}: PageProps) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const view = useLazyGetView(viewId);
  const toEntityLabel = useEntityLabels(view?.source?.scope || "", {
    plural: false,
    case: "title",
  });
  const { isSearching } = useQuickSearch(viewId);
  const { items } = useLazyItemsForView(viewId);
  const entityType = view?.entity || "task";
  const engine = useEngine(entityType);
  const isCard = useMemo(() => view?.layout === "card", [view?.layout]);
  const itemDefaults = useMemo(
    () =>
      view
        ? {
            location: view.location,
            source: { type: entityType, scope: view.source.scope },
          }
        : {},
    [view?.location, view?.entity, view?.source.scope]
  );
  const asItem = useMemo(
    () => (isCard ? engine?.asListCard : engine?.asListItem),
    [view?.layout, isCard, engine]
  );
  const suggested = useSmartProps(view);
  const showProps = useMemo(
    () => whenEmpty(view?.showProps, suggested),
    [suggested?.length, view?.showProps]
  );
  const onReorder = useReorderItemsInView(view, pageId);

  const handleCreated = useCallback(
    (item: OneOrMany<Ref>) => {
      const one = justOne(item);
      if (!one) {
        return;
      }

      if (view?.entity === "form") {
        pushTo([one, "/builder"]);
      } else {
        pushTo(item);
      }
    },
    [view?.entity]
  );

  const handleViewOpen = useCallback(() => view && pushTo(view), [view?.id]);

  const title = useMemo(
    () =>
      sectionLabel ||
      when(view?.entity, (e) => toEntityLabel(e, { plural: true })) ||
      "Work",
    [sectionLabel, view?.entity]
  );
  const shouldCollapse = useMemo(
    () => view?.settings?.collapse || view?.group?.[0]?.collapse,
    [view?.settings?.collapse, items.all?.length]
  );

  if (!view) {
    return <></>;
  }

  return (
    <Container>
      <Centered fit="container" stack="vertical" gap={20}>
        <SpaceBetween>
          <HStack>
            <TextXLarge bold>{title}</TextXLarge>
            <TextXLarge bold subtle>
              {Math.max(items?.all.length, 0) || ""}
            </TextXLarge>
            <ViewQuickSearch viewId={view.id} />
          </HStack>

          <HStack gap={0}>
            <Button
              size="small"
              subtle
              icon={<ViewIcon layout={view?.layout || view.grouping} />}
              iconRight={ArrowUpRight}
              onClick={handleViewOpen}
            >
              Boards
            </Button>

            <SimpleViewOptionsButton view={view} />

            {canCreate(entityType) && (
              <>
                <Divider direction="vertical" margin="on" />
                <AddWorkActionMenu
                  defaults={itemDefaults}
                  onSaved={handleCreated}
                >
                  <Button icon={PlusIcon} size="small" subtle>
                    <Text subtle>New {toEntityLabel(entityType)}</Text>
                  </Button>
                </AddWorkActionMenu>
              </>
            )}
          </HStack>
        </SpaceBetween>

        {view.layout === "browser" && (
          <Container padding="none" inset="both">
            <BrowserLayout id={view.id} />
          </Container>
        )}

        {view.layout !== "browser" &&
          items.grouped &&
          map(items.grouped.groups, (group: GroupedItems, key) => (
            <CollapsibleSection
              key={toKey(group.value)}
              title={
                !isAnyRelation(group.value) || isEmptyRef(group.value) ? (
                  toLabel(group.value)
                ) : (
                  <SectionLabel>
                    <RelationText
                      subtle
                      size="small"
                      relation={group.value.value.relation}
                    />
                  </SectionLabel>
                )
              }
              defaultOpen={!shouldCollapse}
              forceOpen={isSearching}
            >
              <WithViewingWithin scope={toViewingWithinScope(group)}>
                <HStack fit="container" wrap>
                  {map((group as Maybe<GroupedItems>)?.items, (b: Campaign) =>
                    render(asItem, {
                      key: b.id,
                      item: b,
                      group: group,
                      className: !isCard ? styles.listItem : undefined,
                      showProps: showProps,
                      onOpen: pushTo,
                      onReorder,
                    })
                  )}
                </HStack>
              </WithViewingWithin>
            </CollapsibleSection>
          ))}

        {view.layout !== "browser" && !items.grouped && (
          <CollapsibleSection defaultOpen={true}>
            <Container
              padding="none"
              fit="container"
              stack={view.layout === "card" ? "horizontal" : "vertical"}
              wrap
            >
              {map(items.sorted, (b: Campaign) =>
                render(asItem, {
                  key: b.id,
                  item: b,
                  className: !isCard ? styles.listItem : undefined,
                  showProps: showProps,
                  onOpen: pushTo,
                  onReorder,
                })
              )}
            </Container>
          </CollapsibleSection>
        )}
      </Centered>
    </Container>
  );
};

export const EntityViewResultsPage = ({
  parentId,
  childType,
  ...rest
}: EntityViewResultsProps) => {
  const views = useLazyViewsForParent(parentId, childType);
  const view = first(views);

  if (!view) {
    return <></>;
  }

  return <ViewResultsPage viewId={view.id} {...rest} />;
};

const SimpleViewOptions = ({
  view,
  onOpen,
}: {
  view: View;
  onOpen?: Fn<boolean, void>;
}) => {
  const { changes } = useLocalChanges(view.id, ViewStoreAtom);

  return (
    <Container size="half" fit="container">
      <VStack fit="container" gap={20}>
        <FilterOptionsMenu
          viewId={view.id}
          title="Filter"
          showOptions={false}
        />

        <LayoutOptionsMenu
          viewId={view.id}
          title="Layout"
          layouts={["list", "card"]}
          groupings={["rows"]}
        />

        <HStack justify="flex-end" fit="container">
          {!!changes.length && (
            <ViewChangesActions
              viewId={view.id}
              onSaved={() => onOpen?.(false)}
            />
          )}

          {!changes.length && (
            <Button
              size="small"
              variant={"primary"}
              onClick={() => onOpen?.(false)}
            >
              Done
            </Button>
          )}
        </HStack>
      </VStack>
    </Container>
  );
};

const SimpleViewOptionsButton = ({ view }: { view: View }) => {
  const [open, setOpen] = useState(false);
  const { changes } = useLocalChanges(view.id, ViewStoreAtom);

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      closeOnClickAway={false}
      className={{ popover: styles.popover }}
      portal={true}
      trigger={
        <Button
          size="small"
          subtle
          variant={
            changes?.length
              ? "primary-alt"
              : isFiltering(view)
              ? "primary"
              : "secondary"
          }
          icon={FilterAlt}
          onClick={() => setOpen(true)}
        />
      }
    >
      <SimpleViewOptions view={view} onOpen={setOpen} />
    </Dropdown>
  );
};
