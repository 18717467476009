import { useMemo } from "react";

import { EntityType, HasTemplate, ID, toTitleOrName } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";

import { typeFromId } from "@utils/id";
import { safeAs } from "@utils/maybe";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { getEngine, render } from "@ui/engine";
import { EntityPaneManager } from "@ui/entity-pane-manager";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TemplatePaneManager } from "@ui/template-pane-manager";

import AppPage from "./app-page";

export const EntityPage = ({ id }: { id: ID }) => {
  const pageId = usePageId();
  const entity = useLazyEntity(id);
  const engine = useMemo(() => getEngine(typeFromId<EntityType>(id)), [id]);

  const [page] = useRegisterPage(id, entity);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        {render(engine.asPrimaryPane, { id, item: entity })}

        {!safeAs<HasTemplate>(entity)?.template && (
          <Sheet size="secondary" mode="sizing">
            <EntityPaneManager id={entity.id} entity={entity} />
          </Sheet>
        )}

        {!!safeAs<HasTemplate>(entity)?.template && (
          <TemplatePaneManager id={entity.id} entity={entity} />
        )}
      </StackContainer>
    </AppPage>
  );
};

export const EntityOnlyPage = ({
  id,
  full = false,
  children,
}: {
  id: ID;
  full?: boolean;
  children?: React.ReactNode;
}) => {
  const pageId = usePageId();
  const entity = useLazyEntity(id);
  const engine = useMemo(() => getEngine(typeFromId<EntityType>(id)), [id]);
  const [page] = useRegisterPage(id, entity);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        {children ||
          render(engine.asPrimaryPane, {
            id,
            item: entity,
            size: full ? "full" : "primary",
          })}
      </StackContainer>
    </AppPage>
  );
};

export default EntityPage;
