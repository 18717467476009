import { tail } from "lodash";
import { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useUpdateEntity } from "@state/generic";
import { toDisplayName, useMe } from "@state/persons";
import { useAddToRecents } from "@state/recents";
import { useActiveSpace } from "@state/spaces";
import { useToTeamRoute } from "@state/teams";

import { useQueryParams, useStickyState } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { trimSlashes } from "@utils/url";

import { VStack } from "@ui/flex";
import {
  Box,
  ClockHistory,
  EyeSlash,
  Icon,
  PaintTool,
  SquareIcon,
  TagAlt,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RouteMenuItem } from "@ui/menu-item";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { Redirect } from "@ui/redirect";
import { SettingsTeamPackages } from "@ui/settings-packages";
import {
  SettingsTeamProperties,
  SettingsTeamSchedules,
  SettingsTeamTemplates,
} from "@ui/settings-team";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Heading, TextSmall } from "@ui/text";

import AppPage from "./app-page";

import styles from "./team-page.module.css";

interface Props {}

const PrivateSettingsPage = ({}: Props) => {
  const me = useMe();
  const goTo = useGoTo();
  const location = useLocation();
  const params = useQueryParams();
  const space = useActiveSpace();
  const personId = me.id;
  const mutate = useUpdateEntity(personId);
  const [lastPage, setLastPage] = useStickyState<Maybe<string>>(
    undefined,
    `team-${personId}-last-page`
  );
  const childRoute = useMemo(
    () => ({
      pathname:
        "/" + tail(trimSlashes(location.pathname).split("/"))?.join("/"),
    }),
    [location]
  );
  const [page] = useRegisterPage(personId, me);
  usePageUndoRedo(page.id);

  // Add team to recently viewed
  useAddToRecents(personId);

  const toRoute = useToTeamRoute(personId);

  const isOverview = useMemo(
    () => location.pathname === toRoute() || location.pathname === toRoute("/"),
    [location.pathname]
  );

  const size = useMemo(() => {
    const pn = location?.pathname;
    return pn?.includes("v_") || pn?.includes("/packages")
      ? "full"
      : "primary-thicc";
  }, [location.pathname]);

  useEffect(() => {
    if (params.goTo === "last" && isOverview && lastPage) {
      goTo(lastPage, {}, { replace: true });
    }
  }, []);

  useEffect(() => {
    setLastPage(location.pathname);
  }, [setLastPage, location.pathname, isOverview]);

  if (params.goTo === "last") {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size={size} transparency="mid" interactable={false}>
          <PageLayout>
            <SideNav className={styles.nav}>
              <VStack direction="vertical" width="container" gap={20}>
                {me?.source.type === "person" && (
                  <Menu>
                    <VStack gap={6} className={styles.teamName}>
                      <Icon
                        icon={
                          <SquareIcon color="gray_5">
                            <Icon icon={EyeSlash} size="medium" />
                          </SquareIcon>
                        }
                        size="xlarge"
                      />
                      <Heading bold>Private</Heading>
                      <TextSmall subtle>{toDisplayName(me)}</TextSmall>
                    </VStack>
                  </Menu>
                )}

                <Menu>
                  <MenuGroup label="Settings">
                    <RouteMenuItem
                      text="Fields"
                      icon={TagAlt}
                      route={toRoute("/settings/fields")}
                    />

                    <RouteMenuItem
                      text="Packages"
                      icon={Box}
                      route={toRoute("/settings/packages")}
                    />
                  </MenuGroup>

                  <MenuGroup label="Operations">
                    <RouteMenuItem
                      icon={PaintTool}
                      text="Templates"
                      route={toRoute("/settings/templates")}
                    />
                    <RouteMenuItem
                      icon={ClockHistory}
                      text="Recurring"
                      route={toRoute("/settings/schedules")}
                    />
                  </MenuGroup>
                </Menu>
              </VStack>
            </SideNav>

            <Main className={styles.main}>
              <Routes location={childRoute}>
                <Route
                  path="/settings"
                  element={<Redirect to={toRoute("/settings/packages")} />}
                />

                <Route
                  path="/settings/schedules"
                  element={<SettingsTeamSchedules teamId={personId} />}
                />

                <Route
                  path="/settings/fields"
                  element={<SettingsTeamProperties teamId={personId} />}
                />

                <Route
                  path="/settings/templates"
                  element={<SettingsTeamTemplates teamId={personId} />}
                />

                <Route
                  path="/settings/packages"
                  element={<SettingsTeamPackages teamId={personId} />}
                />
              </Routes>
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

export default PrivateSettingsPage;
